import React from "react";
import { Row, Col, Card, Form, Modal, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { reduxForm, reset, SubmissionError, Field } from "redux-form";
import clone from "../../../../assets/images/clone1.svg";
import { connect } from "react-redux";
import back from "../../../../assets/images/back (3).png";
import plus_green from "../../../../assets/images/plus_green.svg";
import edit from "../../../../assets/images/edit_btn.svg";
import plus2 from "../../../../assets/images/plus-circle2.svg";
import plus from "../../../../assets/images/bx-plus (2).svg";
import del from "../../../../assets/images/del_icon.svg";
import Delete from "../../../../assets/images/ux.svg";
import Edit from "../../../../assets/images/ui.svg";
import Navbar from "../../../../layouts/Navbar";
import Sidebar from "../../../../layouts/Sidebar";
import Orange from "../../../../assets/images/green2.svg";
import { toast } from "react-toastify";
import FireDoorForm from "./FireDoorForm";
import {
    changeGroupQutationPage,
    getGroupListQuotation,
    getLocationListOfQuotations,
    createGroupsQuotation,
    getGroupList,
    updateGroupsQuotation,
    deleteGroupListQuotation,
    getQutation,
    changeTitleStr,
    setProduct,
    getProductById,
    changeBoqAddPage,
    displayCurrency,
    addIgnoreFields,
    cloneLocation,
    clearSingleProduct,
    cloneGroup,
    getBulkProducts,
    setGroupDiscount,
    getDataObj,
    getAllUnits,
    changePage
} from "../../../../actions";
import GroupForm from "./GroupForm";
import DeletePopUp from "../../../../Components/deletePopup";
import ViewProducts from "./ViewProducts";
import RowForm from "./RowForm";
import CustomProducts from "./CustomProducts";
import { getLocAmounts } from "../services/servics";
import ViewCustomProducts from "./ViewCustomProduct";
// "/" + props.authRole + "quotations/create-quotation/customerDetails" + props.templateId
const role = localStorage.getItem('userRole');
const renderSelectField = ({ input, label, meta: { touched, error }, children }) => (
    <div className="clone_select">
        <div>
            <label>{label}</label>
            <select {...input} className='form-control form-select  mt-4'>
                {children}
            </select>
            {touched && error && <p className="require" style={{ marginTop: "-23px" }}>{error}</p>}
        </div>
    </div>
);


class QuotationBody extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            empty: "",
            ignoredFields: [],
            locationId: null,
            locId: null,
            str: "",
            group: {},
            productItem: {},
            groupAmounts: [],
            locationOpenedItems: [],
            groupOpenedItems: [],
            deletePopup: false,
            groupId: null,
            groupList: [],
            productPopup: false,
            clonePopup: false,
            customPopup: false,
            locationData: [],
            cloneStr: "",
            counts: [],
            idHide: "",
            showq: false,
            editPopup: null
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.addGroupHandler = this.addGroupHandler.bind(this);
        this.setContent = this.setContent.bind(this);
        this.setGroupContent = this.setGroupContent.bind(this);
        this.deleteItemOpen = this.deleteItemOpen.bind(this);
        this.deleteItemClose = this.deleteItemClose.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.hideColumnHandler = this.hideColumnHandler.bind(this);
        this.productOpen = this.productOpen.bind(this);
        this.productClose = this.productClose.bind(this);
        this.customProductOpen = this.customProductOpen.bind(this);
        this.customProductClose = this.customProductClose.bind(this);
        this.productHandler = this.productHandler.bind(this);
        this.getGroup = this.getGroup.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.cloneHandler = this.cloneHandler.bind(this);
        this.cloneGroupHandler = this.cloneGroupHandler.bind(this);
        this.getLocationDetails = this.getLocationDetails.bind(this);
        this.locationHandler = this.locationHandler.bind(this);
        this.customHandleShow = this.customHandleShow.bind(this);
        this.customHandleClose = this.customHandleClose.bind(this);
    }

    prevPage() {
        const { history } = this.props;
        history.push(`/${role}/quotations/${this.props.match.params.add}/location/${this.props.match.params.id}/${this.props.match.params.quotId}`)
    }
    locationHandler(e) {
        this.setState({
            locId: e.target.value
        })
    }
    nextPage() {
        const { history, groupList } = this.props;
        if (groupList.length === 0) {
            toast("Create Atleast one group");
            return false;
        }
        let data = {
            quotationId: this.props.match.params.quotId,
            ignoredFields: this.state.ignoredFields,
        }
        this.props.dispatch(addIgnoreFields(data));
        history.push(`/${role}/quotations/${this.props.match.params.add}/footer/${this.props.match.params.id}/${this.props.match.params.quotId}`)
    }
    async getLocationDetails() {
        var footerData = await getLocAmounts(this.props.match.params.quotId);
        if (footerData?.data?.status === 200) {
            this.setState({
                locationData: footerData?.data?.data
            })
        }
    }
    handleClose() {
        this.setState({
            show: false,
        })
    }
    handleShow(e, item) {
        e.preventDefault();
        changeGroupQutationPage();
        this.setState({
            show: true,
            locationId: item.locationId,
            productPopup: false,
            customPopup: false,
            group: {
                "discount": 0
            },
            str: "add"
        })
    }
    handleEdit(e, val) {
        e.preventDefault();
        this.setState({
            show: true,
            group: val,
            str: "edit"
        })
    }
    hideColumnHandler(event) {
        const target = event.target;
        var value = target.value;
        let fields = this.state.ignoredFields;
        if (fields.includes(value)) {
            let newColumns = fields.filter(item => item !== value);
            this.setState({
                ignoredFields: newColumns
            });
        } else {
            fields.push(value);
            this.setState({
                ignoredFields: fields
            });
        }
    }
    cloneHandler(e, id) {
        e.preventDefault();
        this.setState({
            locationId: id,
            clonePopup: true,
            cloneStr: "location"
        });
    }
    cloneGroupHandler(e, id) {
        e.preventDefault();
        this.setState({
            groupId: id,
            clonePopup: true,
            cloneStr: "group"
        });
    }
    setContent(e, val, index) {
        e.preventDefault();
        !val ? this.state.locationOpenedItems[index] = true : this.state.locationOpenedItems[index] = false;
        this.setState({
            productPopup: false,
            customPopup: false,
        })
    }
    setGroupContent(e, val, index) {
        e.preventDefault();
        !val ? this.state.groupOpenedItems[index] = true : this.state.groupOpenedItems[index] = false;
        this.setState({
            productPopup: false,
            customPopup: false,
        })
    }
    addGroupHandler(formData, dispatch) {
        console.log(formData);
        if (!formData.groupName) {
            throw new SubmissionError({
                groupName: 'Please fill the field *',
            })
        } else if (!formData.locationId) {
            throw new SubmissionError({
                locationId: 'Please fill the field *',
            })
        }
        // else if (!formData.discount) {
        //     throw new SubmissionError({
        //         discount: 'Please fill the field *',
        //     })
        // }
        setGroupDiscount(formData.discount);
        if (this.state.str === "add") {
            let matrix = {
                'groupName': formData.groupName,
                'quotationId': Number(this.props.match.params.quotId),
                'locationId': Number(formData.locationId),
                'discount': Number(formData.discount)
            }
            this.props.dispatch(createGroupsQuotation(matrix));
            setTimeout(() => dispatch(reset("GroupForm")), 500);
        } else {
            let matrix = {
                'groupName': formData.groupName,
                'quotationId': Number(this.props.match.params.quotId),
                'locationId': Number(formData.locationId),
                'groupId': formData.groupId,
                'discount': Number(formData.discount)
            }
            this.props.dispatch(updateGroupsQuotation(matrix));
        }
    }
    deleteItemOpen(item) {
        this.setState({
            deletePopup: true,
            groupId: item.groupId,
            productPopup: false,
            customPopup: false
        })
    }
    deleteItemClose() {
        this.setState({
            deletePopup: false
        })
    }
    deleteGroup() {
        this.props.dispatch(deleteGroupListQuotation(this.state.groupId));
        this.getLocationDetails();
    }
    productOpen(e, item, val, index) {
        e.preventDefault();
        if (!val) {
            this.state.groupOpenedItems[index] = true;
        }
        this.props.dispatch(clearSingleProduct());
        this.props.dispatch(changeBoqAddPage());
        setGroupDiscount(item.discount);
        localStorage.removeItem("quantity");
        setProduct({})
        this.setState(prevState => ({
            str: "add",
            customPopup: false,
            productPopup: !prevState.productPopup,
            groupId: item.groupId,
            productItem: {},
        }));
    }
    productClose() {
        this.setState(prevState => ({
            productPopup: !prevState.productPopup,
        }));
    }
    customProductOpen(e, item) {
        e.preventDefault();
        this.props.dispatch(changePage());
        setProduct({})
        getDataObj(Number(this.props.match.params.id));
        this.props.dispatch(getAllUnits());
        this.props.dispatch(changeBoqAddPage());
        // setGroupDiscount(item.discount);
        this.setState(prevState => ({
            str: "add",
            customPopup: !prevState.customPopup,
            groupId: item.groupId,
            productItem: {
                "discount": item.discount,
                "productName": ""
            }
        }));
    }
    customProductClose() {
        this.setState(prevState => ({
            customPopup: !prevState.customPopup,
            productPopup: false
        }));
    }
    productHandler(values) {
        this.props.dispatch(clearSingleProduct());
        if (values?.productType === "DYNAMIC") {
            this.props.dispatch(getAllUnits());
            this.props.dispatch(getProductById(values.productId));
            this.setState(prevState => ({
                customPopup: !prevState.customPopup,
                productPopup: false,
                str: "edit",
                productItem: values
            }));
            setProduct(values);
        } else {
            setProduct(values);
            localStorage.setItem("quantity", values?.quantity);
            setGroupDiscount(values.discount);
            this.setState(prevState => ({
                productPopup: !prevState.productPopup,
                customPopup: false,
                str: "edit",
                productItem: values
            }));
        }
    }
    getGroup() {
        this.props.dispatch(getGroupListQuotation(this.props.match.params.quotId));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.addGroupSuccess !== this.props.addGroupSuccess) {
            this.setState({
                show: false,
                group: {}
            });
            this.getLocationDetails();
            if (this.props.addGroupSuccess.message !== "") toast(this.props.addGroupSuccess.message);
            this.props.dispatch(getGroupListQuotation(this.props.match.params.quotId));
        } if (prevProps.updateGroupSuccess !== this.props.updateGroupSuccess) {
            this.setState({
                show: false
            });
            if (this.props.updateGroupSuccess.status === 200) {
                this.getLocationDetails();
                toast(this.props.updateGroupSuccess.data);
                this.props.dispatch(getGroupListQuotation(this.props.match.params.quotId));
            } else {
                toast(this.props.updateGroupSuccess.data);
            }
        } if (prevProps.quoteLocations !== this.props.quoteLocations) {
            const { locationOpenedItems } = this.state;
            let data = [];
            this.props.quoteLocations?.map((item, i) => {
                if (locationOpenedItems[i] && data[i]) {
                    data[i] = true
                } else {
                    data[i] = false
                }
            });
            this.setState({
                locationOpenedItems: data
            })
        } if (prevProps.deleteGroupSuccess !== this.props.deleteGroupSuccess) {
            this.setState({
                deletePopup: false
            });
            if (this.props.deleteGroupSuccess.status === 200) {
                // toast(this.props.deleteGroupSuccess.data);
                this.getLocationDetails();
                this.props.dispatch(getGroupListQuotation(this.props.match.params.quotId));
            } else {
                toast(this.props.deleteGroupSuccess.data);
            }
        } if (prevProps.quotationData !== this.props.quotationData) {
            this.setState({
                ignoredFields: this.props.quotationData?.ignoredFields
            });
        } if (prevProps.groupList !== this.props.groupList) {
            this.setState({
                groupList: this.props.groupList
            })
            let data = [];
            let groupItems = [];
            let count = 0;
            this.props.quoteLocations?.map((loc, index) => {
                this.props.groupList?.map((item, i) => {
                    if (item.locationId === loc.locationId) {
                        let productDetail = item.productDetails || item?.customProductResDtoList;
                        count++;
                        if (productDetail?.length > 0 && this.state.groupOpenedItems[i]) {
                            data[i] = true;
                        } else {
                            data[i] = false;
                        }
                    }
                    groupItems[index] = count;
                });
                this.setState({
                    counts: groupItems
                })
                count = 0;
            })
            this.setState({
                groupOpenedItems: data
            })
        } if (prevProps.cloneLocationSuccess !== this.props.cloneLocationSuccess) {
            this.setState({
                cloneLocationSuccess: this.props.cloneLocationSuccess,
                clonePopup: false
            });
            this.getLocationDetails();
            this.props.dispatch(getLocationListOfQuotations(this.props.match.params.quotId));
            this.props.dispatch(getGroupListQuotation(this.props.match.params.quotId));
            toast(this.props.cloneLocationSuccess?.message);
        } if (prevProps.cloneGroupSuccess !== this.props.cloneGroupSuccess) {
            this.setState({
                clonePopup: false,
                locId: null
            });
            this.props.reset();
            this.props.dispatch(getGroupListQuotation(this.props.match.params.quotId));
            this.getLocationDetails();
            toast(this.props.cloneGroupSuccess?.message);
        } if (prevProps.deleteGroupProducts !== this.props.deleteGroupProducts) {
            const { deleteGroupProducts } = this.props;
            if (deleteGroupProducts.status === 200) {
                this.deleteItemClose();
                this.setState({
                    deletePopup: false
                })
                this.getLocationDetails();
                this.props.dispatch(getGroupListQuotation(this.props.match.params.quotId));
                // this.props.getList();
                toast(deleteGroupProducts.data);
            } else {
                toast(deleteGroupProducts.data);
            }

        }
    }
    componentDidMount() {
        let { id } = this.props.match.params;
        this.setState({
            idHide: id
        })
        if (this.props.match.params.quotId !== "null") {
            this.props.dispatch(getGroupList(0));
            this.getLocationDetails();
            this.props.dispatch(getGroupListQuotation(this.props.match.params.quotId));
            this.props.dispatch(getLocationListOfQuotations(this.props.match.params.quotId));
            this.props.dispatch(getQutation(this.props.match.params.quotId));

        }
    }

    customHandleShow(val) {
        this.setState({ showq: true, groupId: val.groupId, editPopup: val });
    }
    customHandleClose() {
        this.setState({ showq: false, editPopup: null });
    }


    render() {
        const { ignoredFields,
            show,
            group,
            str,
            locationOpenedItems,
            deletePopup,
            groupId,
            productPopup,
            productItem,
            customPopup,
            clonePopup,
            cloneStr,
            locationData,
            locationId,
            locId,
            idHide,
            showq,
            editPopup
        } = this.state;
        const { quoteLocations, group_list, reset, groupItems } = this.props;

        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <img src={Orange} alt="..." className="head-img" />
                            <h5 className='po_link' onClick={() => window.location.hash = "/" + role + "/quotations"}>Quotations</h5>
                            <i className="fas fa-arrow-right" style={{ margin: "10px 0 0 -10px" }}></i>
                            <h6 >{changeTitleStr(this.props.match.params.add)}</h6>
                            <hr className="line-new"></hr>
                            <div className="order-page-title">
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/customerDetails/${this.props.match.params.id}/${this.props.match.params.quotId}`}>
                                    Customer Details
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/contentBody/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Content of the Letter
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/location/${this.props.match.params.id}/${this.props.match.params.quotId}`} >
                                    Location
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/quotationBody/${this.props.match.params.id}/${this.props.match.params.quotId}`} className="active" >
                                    Quotation Body
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/footer/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Footer
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/termsAndConditions/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Terms & Conditions
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/prepared/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Prepared by
                                </Link>
                            </div>
                            <div className="purchase_order_new">
                                <section className="po-header">
                                    <Row className='ignore_field_body' >
                                        <Col md={2}>
                                            <h4>Project Groups</h4>
                                        </Col>
                                        <Col md={5}>
                                            <h4 className="txt-right">Hide Columns:</h4>
                                        </Col>
                                        <Col md={5}>
                                            <div className="qutation_check">
                                                <div className="customer-check">
                                                    <Form.Check checked={ignoredFields &&
                                                        ignoredFields?.includes("ARTICLE_ID")
                                                        ? true : false} inline label="Article ID" onChange={this.hideColumnHandler} name="group1" type="checkbox" defaultValue="ARTICLE_ID" className="form-check-label" />
                                                </div>
                                                <div className="customer-check">
                                                    <Form.Check checked={ignoredFields &&
                                                        ignoredFields?.includes("PRODUCT_IMAGE")
                                                        ? true : false} inline label="Product Image" onChange={this.hideColumnHandler} name="group1" type="checkbox" defaultValue="PRODUCT_IMAGE" className="form-check-label" />
                                                </div>
                                                <div className="customer-check">
                                                    <Form.Check checked={ignoredFields &&
                                                        ignoredFields?.includes("UNIT_COST")
                                                        ? true : false} inline label="Unit Cost." onChange={this.hideColumnHandler} name="group1" type="checkbox" defaultValue="UNIT_COST" className="form-check-label" />
                                                </div>
                                                <div className="customer-check">
                                                    <Form.Check checked={ignoredFields &&
                                                        ignoredFields?.includes("DISCOUNT")
                                                        ? true : false} inline label="Discount" onChange={this.hideColumnHandler} name="group1" type="checkbox" defaultValue="DISCOUNT" className="form-check-label" />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </section>
                                <div className="qutation-page">
                                    <form >
                                        <div className="qutation-page">
                                            {this.props.quoteLocations && this.props?.quoteLocations?.length === 0
                                                && <h1 className="empty-data" style={{ margin: "50px 0" }}>Add Atleast One Location </h1>
                                            }
                                            {this.props.quoteLocations ?? this.props?.quoteLocations?.length > 0 ? this.props?.quoteLocations?.map((loc, index) => {
                                                return (
                                                    <>
                                                        <Row key={index} className="orange-trigger">
                                                            <Col md={11} className="orange-triggers" onClick={(e) => this.setContent(e, this.state.locationOpenedItems[index], index)}>
                                                                <Row>
                                                                    <Col md={4} >
                                                                        <h1>Area : <span>{loc.area}</span></h1>
                                                                    </Col>
                                                                    <Col md={3} >
                                                                        <h1>Area Size : <span>{loc.dimensions}</span></h1>
                                                                    </Col>
                                                                    <Col md={2} >
                                                                        <h1>Dwg Ref No : <span>{loc.drawingReferenceNumber}</span></h1>
                                                                    </Col>
                                                                    <Col md={1} className="orange-separtae-row">
                                                                        <h1>
                                                                            {groupItems[index] === 0 || groupItems.length === 0 ? "No Group Added" : groupItems[index] > 1 ? `${groupItems[index] || ""} Groups  ${locationData[index]?.totalAmount !== 0 ? "Total :" : ""}  ${locationData[index]?.totalAmount !== 0 ? displayCurrency(locationData[index]?.totalAmount) : ""}` :
                                                                                `${groupItems[index] || ""}  Group  ${locationData[index]?.totalAmount !== 0 ? "Total :" : ""}  ${locationData[index]?.totalAmount !== 0 ? displayCurrency(locationData[index]?.totalAmount) : ""}`}</h1>
                                                                    </Col>
                                                                    <Col md={2} style={{ textAlign: "right" }} >
                                                                        <h1 className="txt-right"> Set Count : <span>{loc.setCount}</span></h1>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={1}>
                                                                <div className="added_content">
                                                                    <button className="modal-btn quot_loc" onClick={(e) => this.handleShow(e, loc)} >
                                                                        <img src={plus} alt="" />
                                                                    </button>
                                                                    <img src={clone} className="quot_add_img" alt=".." onClick={(e) => this.cloneHandler(e, loc.locationId)} />
                                                                </div>
                                                            </Col>
                                                            {locationOpenedItems[index] ?
                                                                this.props?.groupList && this.props?.groupList?.map((group, i) => {
                                                                    return (
                                                                        loc.locationId === group.locationId ?
                                                                            <>
                                                                                {<Row key={index} className="group_new_body" >
                                                                                    <Col md={3} >
                                                                                        {/* <div> */}
                                                                                        <label class="form-control-label" for="name">Group Name <span>*</span></label>
                                                                                        <Form.Group controlId="enterarea">
                                                                                            <Form.Control
                                                                                                type="text"
                                                                                                name="groupName"
                                                                                                value={group.groupName}
                                                                                                readOnly
                                                                                            />
                                                                                        </Form.Group>
                                                                                        {/* </div> */}
                                                                                    </Col>
                                                                                    <Col md={2}>
                                                                                        <label class="form-control-label" for="name">No .of Products <span>*</span></label>
                                                                                        <Form.Group controlId="enterdwg">
                                                                                            <Form.Control
                                                                                                type="text"
                                                                                                name="numberOfProducts"
                                                                                                value={group.numberOfProducts}
                                                                                                readOnly
                                                                                            />
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col md={3}>
                                                                                        <label class="form-control-label" for="name">Total Amount <span>*</span></label>
                                                                                        <Form.Group controlId="enterdimention">
                                                                                            <Form.Control
                                                                                                type="text"
                                                                                                name="totalAmount"
                                                                                                value={displayCurrency(group.totalAmount)}
                                                                                                readOnly
                                                                                            />
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col md={3} className="project_body"  >
                                                                                        {group?.productDetails?.length > 0 && <button className='view_po_btn' style={{ width: "50%" }} onClick={(e) => this.setGroupContent(e, this.state.groupOpenedItems[i], i)}>
                                                                                            {this.state.groupOpenedItems[i] ? "Hide" : "View"}
                                                                                        </button>}
                                                                                        {group?.productDetails?.length > 0 && <button className='add_po_btn' style={{ width: "50%" }} onClick={(e) => this.productOpen(e, group, this.state.groupOpenedItems[i], i)}>
                                                                                            <img src={plus_green} alt=".../" />
                                                                                            Add Product
                                                                                        </button>}
                                                                                        {/* new custom details */}
                                                                                        {idHide == 7 ?
                                                                                            <>
                                                                                                {group?.customProductResDtoList?.length > 0 && <button className='view_po_btn' style={{ width: "45%", marginRight: "20px" }} onClick={(e) => this.setGroupContent(e, this.state.groupOpenedItems[i], i)}>
                                                                                                    {!this.state.groupOpenedItems[i] ? "View" : "Hide"}
                                                                                                </button>}
                                                                                                <Button className="add-button-two" style={{ padding: "10px 0px" }} onClick={() => this.customHandleShow(group)} ><img src={plus2} alt="" />New Custom Product</Button>

                                                                                            </>
                                                                                            : ""}
                                                                                        {group?.productDetails?.length === 0 &&
                                                                                            <div className="new_add_product" >
                                                                                                <button onClick={(e) => this.productOpen(e, group, this.state.groupOpenedItems[i], i)}
                                                                                                    className="add-button-one" style={{ margin: "5px 10px 0 -15px" }}>
                                                                                                    <img src={plus2} alt="" />
                                                                                                    Add Product</button>
                                                                                                <button className="add-button-two" style={{ margin: "5px 0px 0 0px" }} onClick={(e) => this.customProductOpen(e, group)}><img src={plus2} alt="" />Add Custom Product</button>
                                                                                            </div>
                                                                                        }
                                                                                    </Col>
                                                                                    <Col md={1} className="loc_actions">
                                                                                        <img src={Edit} onClick={(e) => this.handleEdit(e, group)} alt=".." className="fog-edit" width="35px" />
                                                                                        <img src={clone} alt=".." onClick={(e) => this.cloneGroupHandler(e, group.groupId)} width="35px" />
                                                                                        <img src={Delete} alt=".." className="fog-edits" onClick={() => this.deleteItemOpen(group)} width="35px" />
                                                                                    </Col>
                                                                                    {/* group details all */}
                                                                                    {this.state.groupOpenedItems[i] && group?.productDetails?.length > 0 ?
                                                                                        < >
                                                                                            <ViewProducts
                                                                                                data={group?.productDetails}
                                                                                                purchaseId={this.props.match.params.id}
                                                                                                handleShow={this.productHandler}
                                                                                                getList={this.getGroup}
                                                                                                quotId={Number(this.props.match.params.quotId)}
                                                                                                getAmounts={this.getLocationDetails}
                                                                                            />
                                                                                            <div className='blue_body' style={{ width: "100%", margin: "0 0 0 18px" }}>
                                                                                                <button onClick={(e) => this.productOpen(e, group)}
                                                                                                    className="group_product_btn" style={{ marginRight: "30px" }}>
                                                                                                    <img src={plus2} alt="" />
                                                                                                    Add Product
                                                                                                </button>
                                                                                                {idHide == 7 ? <Button onClick={this.customHandleShow} style={{ margin: "5px 0px 0 0px" }} ><img src={plus2} alt="" />New Custom Product</Button>
                                                                                                    : <button className="group_product_btns" onClick={(e) => this.customProductOpen(e, group)}><img src={plus2} alt="" />Add Custom Product</button>}
                                                                                            </div>
                                                                                        </>
                                                                                        : <></>}

                                                                                    {/* Group details new custom */}
                                                                                    {this.state.groupOpenedItems[i] && idHide == 7 && group?.customProductResDtoList?.length > 0 &&
                                                                                        < >
                                                                                            <ViewCustomProducts
                                                                                                handleValue={this.customHandleShow}
                                                                                                data={group?.customProductResDtoList}
                                                                                                allData={group}
                                                                                            />
                                                                                        </>
                                                                                    }
                                                                                </Row>}
                                                                            </>
                                                                            : <></>
                                                                    )
                                                                })
                                                                : null
                                                            }
                                                        </Row>
                                                        <br></br>
                                                    </>
                                                )
                                            }) : null}
                                            <Row >
                                                <Col md={4}>
                                                    <button onClick={this.prevPage} className="cancel-btn qutation-btn new-btn" >
                                                        <img src={back} />
                                                        Back
                                                    </button>
                                                </Col>
                                                <Col md={5}></Col>
                                                <Col md={3}>
                                                    <button type="submit" onClick={this.nextPage} className="modal-btn save new-btn">Save And Proceed</button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Card>
                        <GroupForm
                            show={show}
                            onHide={this.handleClose}
                            onSubmit={this.addGroupHandler}
                            locations={quoteLocations}
                            initialValues={group}
                            str={str}
                            groups={group_list}
                        />
                        <DeletePopUp
                            show={deletePopup}
                            onHide={this.deleteItemClose}
                            deleteItem={this.deleteGroup}
                        />
                        <div >
                            <RowForm
                                getList={this.getGroup}
                                show={productPopup}
                                onHide={this.productClose}
                                groupId={groupId}
                                data={productItem}
                                str={str}
                                getAmounts={this.getLocationDetails}
                            />
                        </div>
                        <div >
                            <CustomProducts
                                getList={this.getGroup}
                                show={customPopup}
                                onHide={this.customProductClose}
                                groupId={groupId}
                                initialValues={productItem}
                                str={str}
                                id={Number(this.props.match.params.id)}
                                getAmounts={this.getLocationDetails}
                            />
                        </div>
                        <Modal
                            show={clonePopup}
                            onHide={() => {
                                reset();
                                this.setState({
                                    clonePopup: false
                                });
                            }}
                            size="sm"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <div className="modal-success">
                                <img src={clone} alt=".." />
                                <h5> Are you sure you want to clone this {cloneStr === "group" ? "Group" : "Location"}</h5>
                            </div>
                            {cloneStr === "group" && <Field name="locationId" component={renderSelectField} label="Select One Area" onChange={this.locationHandler}>
                                <option value="" disabled={true} hidden={true}></option>
                                {quoteLocations?.map((item, i) =>
                                    <option value={item?.locationId} key={i}>{item?.area}</option>)}
                            </Field>}
                            <div className="txt-center">
                                <button onClick={() => {
                                    this.setState({
                                        clonePopup: false
                                    });
                                }}
                                    className="no_btn"
                                >
                                    Cancel
                                </button>
                                <button onClick={() => {
                                    this.setState({
                                    });
                                    if (cloneStr === "group") {
                                        if (locId !== null) {
                                            let data = {
                                                "groupId": groupId,
                                                "locationId": Number(locId)
                                            }
                                            this.props.dispatch(cloneGroup(data));
                                        } else {
                                            toast("Please Select Location")
                                        }
                                    } else {
                                        this.props.dispatch(cloneLocation(locationId));
                                    }
                                }
                                } className="okay"> Okay
                                </button>
                            </div>
                        </Modal>
                    </div>
                </div>
                <FireDoorForm getLists={this.getGroup} groupId={groupId} show={showq} customHandleClose={this.customHandleClose} editPopup={editPopup} />
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    let count = 0;
    let groupItems = [];
    let locations = state.qutation?.quoteLocations;
    let groups = state.qutation?.groupList;
    locations?.map((loc, index) => {
        groups?.map((item) => {
            if (item.locationId === loc.locationId) {
                count++;
            }
            groupItems[index] = count;
        });
        count = 0;
    })

    return {
        groupItems: groupItems,
        addLocationSuccess: state.qutation.addLocationSuccess,
        hideColumns: state.qutation.hideColumns,
        quoteLocations: state.qutation.quoteLocations,
        groupList: state.qutation.groupList,
        group_list: state.group.allData,
        groupProducts: state.qutation.groupProducts,
        addGroupSuccess: state.qutation.addGroupSuccess,
        updateGroupSuccess: state.qutation.updateGroupSuccess,
        deleteGroupSuccess: state.qutation.deleteGroupSuccess,
        quotationData: state.qutation.quotationData,
        cloneLocationSuccess: state.qutation.cloneLocationSuccess,
        cloneGroupSuccess: state.qutation.cloneGroupSuccess,
        deleteGroupProducts: state.qutation.deleteGroupProducts,
    }
}
const VendorDetailsForm = reduxForm({
    form: 'GroupBodyForm',
    destroyOnUnmount: false,
    enableReinitialize: true,
    // validate
})(QuotationBody)

export default connect(mapStateToProps)(VendorDetailsForm);
