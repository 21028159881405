import React from 'react';
import { RichTextEditor } from '@mantine/rte';

const MantineEditor = ({ initialContent, onChange }) => {

    const handleModelChange = (newContent) => {
        onChange(newContent);
    };

    return (
        <RichTextEditor
        id="rte"
        controls={[
          ['bold', 'italic', 'underline', 'strike'], 
          ['quote'],
          ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
          ['sup', 'sub'],
          ['alignLeft', 'alignCenter', 'alignRight'],
          ['undo', 'redo'],
        ]}
        value={initialContent}
        onChange={handleModelChange}
      />
    );
};

export default MantineEditor;
