import React, { Fragment, useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Input, Select } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import plus from "../../../assets/images/pl.svg";
import { connect } from "react-redux";
import { addPackageProductAction, editPackageProductAction, getBulkProducts, getProductById, getProductDropdown } from "../../../actions";
import { toast } from "react-toastify";

const { Option } = Select;

const ProductForm = (props) => {

    const { triggerProductDropDown, productDropDownData, triggerProductId, singleProductDropDownData, addProductDispatch, addPackageLoading } = props;
    const { reset, productItem, editProductDispatch, addProductResponse } = props;
    const ref = useRef();
    let history = useHistory();
    const [form] = Form.useForm();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const packageId = queryParams.get('pid');
    const productId = queryParams.get('productId');
    const createId = queryParams.get('create');
    const packagePid = queryParams.get('packageProductId')

    const [searchKey, setSearchKey] = useState("");
    const [showPopUp, setShowPopUp] = useState(false);
    const [quantityError, setQuantityError] = useState("");
    const [productError, setProductError] = useState("");

    const handleShow = () => {
        setShowPopUp((prevIsToggled) => !prevIsToggled);
    }

    const checkIsFromProduct = () => {
        return createId === "1"
    }

    const handleClose = () => {
        setShowPopUp(false);
        reset(singleProductDropDownData?.productId);
        form.resetFields(["productName", "quantity", "articleId", "unitPrice", "unitName", "totalStock"]);
        history.push(`?${checkIsFromProduct() ? "create=1&" : ""}pid=${packageId}`);
    }

    const handleSearchProducts = (value) => {
        setSearchKey(value);
    }

    const checkDataIsEmpty = (value) => {
       return value === null || value === undefined || value === "";
    }

    const checkValidQuantity = () => {
        if(parseInt(form.getFieldValue("quantity")) <= 0) {
            return false;
        }
        return true;
    }

    const validateProductField = () => {
        if(checkDataIsEmpty(form.getFieldValue("productName"))) {
            setProductError("Select a product!");
            return false;
        }
        setProductError("");
        return true;
    }

    const validateQuantityField = () => {
        if(checkDataIsEmpty(form.getFieldValue("quantity"))) {
            setQuantityError("Enter quantity!");
            return false;
        }
        if(checkValidQuantity() === false) {
            setQuantityError("Enter valid quantity");
            return false;
        }
        setQuantityError("");
        return true;
    }

    const addProduct = () => {
        let requestJSON = {
            packageId : packageId,
            productId : singleProductDropDownData?.productId,
            quantity : parseInt(form.getFieldValue("quantity"))
        }
        addProductDispatch(requestJSON);
    }

    const editProduct = () => {
        let requestJSON = {
            packageProductId : packagePid,
            productId : singleProductDropDownData?.productId,
            quantity : parseInt(form.getFieldValue("quantity"))
        }
        editProductDispatch(requestJSON);
    }

    const onFinish = (event) => {
        event.preventDefault();
        validateProductField();
        validateQuantityField();
        if(validateProductField() && validateQuantityField()) {
            if(productId) {
                editProduct()
            } else {
                addProduct();
            }
        }
    }

    const onSelectProduct = (value, event) => {
        validateProductField();
        triggerProductId(value);
    }

    // default call
    useEffect(() => {
        triggerProductDropDown("");
    }, [])

    //product search
    useEffect(() => {
        if (searchKey) {
            triggerProductDropDown(searchKey);
        }
    }, [searchKey])

    useEffect(() => {
        if(productId) {
            if(showPopUp === false) {
                handleShow();
            }
            form.setFieldsValue({
                "quantity": productItem?.quantity
            })
            if(checkDataIsEmpty(productItem?.productId) === false) {
                triggerProductId(productItem?.productId);
            }
        }
    },[productId])

    // autosetvalue
    useEffect(() => {
        if (singleProductDropDownData) {
            form.setFieldsValue({
                "productName": singleProductDropDownData?.productName,
                "articleId": singleProductDropDownData?.articleId,
                "unitName": singleProductDropDownData?.unitName,
                "totalStock": singleProductDropDownData?.totalStock
            })
        }
    }, [singleProductDropDownData])

    useEffect(() => {
        if(addProductResponse) {
            handleClose();
            toast(addProductResponse?.data);
        }
    }, [addProductResponse])

    return (
        <Fragment>
            <div className="add-product-pack-div">
                <Button className='add_po_button' onClick={handleShow}><img src={plus} alt=".../" />Add Product</Button>
            </div>
            {showPopUp ?
                <div className='mini-popup product_pop pack-prod-pop' show={showPopUp}>
                    <Form form={form} ref={ref} name="add-product-form" className="product-label-form">
                        <Row gutter={[16, 16]}>
                            <Col md={1}>
                                <i class="fas fa-arrow-left" onClick={handleClose}></i>
                            </Col>
                            <Col md={23}>
                                <label className={'form-control-placeholder-pack'}>Product <span>*</span></label>
                                <Form.Item name="productName">
                                    <Select
                                        showSearch
                                        onSearch={handleSearchProducts}
                                        onChange={onSelectProduct}
                                        optionFilterProp="children"
                                    >
                                        {productDropDownData?.map((option, a) => (
                                            <Option key={a} value={option.productId} label={option.productName}>
                                                {option.productName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {productError !== "" ? <p>{productError}</p> : null}
                            </Col>
                            <Col md={5}>
                                <label className='form-control-placeholder-pack'>Article ID</label>
                                <Form.Item name="articleId">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col md={5}>
                                <label className='form-control-placeholder-pack'>Quantity <span>*</span></label>
                                <Form.Item name="quantity">
                                    <Input onChange={validateQuantityField} type="number"/>
                                </Form.Item>
                                {quantityError !== "" ? <p>{quantityError}</p> : null}
                            </Col>
                            <Col md={5}>
                                <label className='form-control-placeholder-pack'>Unit Name</label>
                                <Form.Item name="unitName">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col md={5}>
                                <label className='form-control-placeholder-pack'>Total Stock</label>
                                <Form.Item name="totalStock">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col md={4}>
                                <Form.Item>
                                    <Button onClick={onFinish} className="package-button" htmlType="submit" loading={addPackageLoading}>{`${productId ? "Update" : "Add"}`} Product</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                : null}
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    const { product, packages } = state;
    return {
        loading: product.loading,
        addPackageLoading: packages.addPackageLoad,
        addProductResponse: packages.addPackage,
        productDropDownData: packages.productDropdownList.content,
        singleProductDropDownData: product.singleProduct
    }
}

const mapDispatchToProps = (dispatch) => ({
    triggerProductDropDown: (searchKey) => dispatch(getProductDropdown(searchKey)),
    triggerProductId: (productId) => dispatch(getProductById(productId)),
    addProductDispatch: (req) => dispatch(addPackageProductAction(req)),
    editProductDispatch: (req) => dispatch(editPackageProductAction(req)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm)