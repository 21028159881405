import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import { clearAddOrEditPackageResponseAction, getPackages } from "../../../actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PackageImg from "../../../assets/images/package.png";
import Search from "../../../assets/images/search.svg";
import Spinner from "../../../Components/loader";
import NavBar from "../../../layouts/Navbar";
import SideBar from "../../../layouts/Sidebar";
import PackageForm from "./packageForm";
import { connect } from "react-redux";

const Packages = (props) => {
    const {onPackageListDispactch, loading, packagesData,  packageAll,  onClearAddOrEditProductDispatch} = props;
    let history = useHistory();
    const [searchKey, setSearchKey] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [initialValues, setInitialValues] = useState({
            searchKey: searchKey,
            pageNumber: pageNumber,
            pageSize: 10
    })
    const [selectedPackage, setSelectedPackage] = useState(null);

    const handleSearch = (event) => {
        initialValues.searchKey = event.target.value;
        setSearchKey(event.target.value);
    }

    const onChange = () => {
        initialValues.pageNumber = pageNumber + 1;
        setInitialValues(initialValues);
        setPageNumber(pageNumber + 1);
    }

    const resetForm = () => {
        setSelectedPackage(null);
        onClearAddOrEditProductDispatch();
        getPackagesList();
    }

    const getPackagesList = () => {
        onPackageListDispactch(initialValues);
    }

    useEffect(() => {
        getPackagesList();
    },[searchKey, pageNumber]);

    return (
        <div>
            <NavBar />
            <div className="dashboard-page">
                <div className="dashboard-sidebar">
                    <SideBar />
                </div>
                <div className="dashboard-main">
                    <Card className="staff-page">
                        <div className="page-head-split">
                            <div className="head-colums">
                                <div className="head-part-tabs-one">
                                    <img src={PackageImg} alt="..." className="head-imgs" />
                                    <h5> Package Configuration</h5>
                                </div>
                                <div className="package-search">
                                    <span><img src={Search} alt=".." className="search-img" /></span>
                                    <Form.Control onChange={handleSearch} type="search" placeholder="Search" className="main-search" />
                                </div>
                                <PackageForm packageDetails={selectedPackage} reset={resetForm}/>
                            </div>
                        </div>
                        <div className="table-class" id="scrollableDiv">
                        <InfiniteScroll
                                dataLength={packageAll?.content?.length}
                                next={onChange}
                                hasMore={packageAll?.content?.length < packagesData?.totalElements}
                                loader={<p className='txt-center'><h4>Loading...</h4></p>}
                                endMessage={<p className='txt-center'>{packageAll?.content?.length === 0 ? <p className='no-data-hg'> <b >{loading ? <Spinner /> : "No Data Found"}</b></p> : <b>Yay! You have seen it all</b>}</p>}
                                refreshFunction={() => { }}
                                pullDownToRefresh
                                scrollableTarget="scrollableDiv"
                                pullDownToRefreshThreshold={60}
                                pullDownToRefreshContent={<h3 className='txt-center'>&#8595; Pull down to refresh</h3>}
                                releaseToRefreshContent={<h3 className='txt-center'>&#8593; Release to refresh</h3>}
                            >
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">Article ID</th>
                                        <th scope="col">Pacakge Name</th>
                                        <th scope="col">Unit Price</th>
                                        <th scope="col">No. of Products</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {packageAll?.content?.map((item, a) => (
                                  <tr key={a}>
                                    <td>{item?.articleId}</td>
                                    <td>{item?.packageName}</td>
                                    <td>{item?.unitPrice}</td>
                                    <td>{item?.noOfProducts}</td>
                                    <td>
                                        <button className="Edit-Package" onClick={(e) => {
                                            history.push(`?pid=${item?.packageId}`)
                                            setSelectedPackage(item);
                                            }}>
                                            Edit
                                        </button>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                            </table>
                            </InfiniteScroll>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const {packages} = state;
    return {
        loading: packages.packLoad,
        packagesData: packages.packageAll,
        packageAll: { content: packages.newData }
    }
}

const mapDispatchToProps = (dispatch) => ({
    onPackageListDispactch: (formData) => dispatch(getPackages(formData)),
    onClearAddOrEditProductDispatch: () => dispatch(clearAddOrEditPackageResponseAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Packages);