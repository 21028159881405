import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Modal } from "react-bootstrap";
import validate from '../../../purchaseOrder/createpurchaseorder/footer/validate';

const textField = ({ input, placeholder, maxLength, type, meta: { touched, error } }) => (
    <div>
        <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength} />
        {touched && error && <p className="require" >{error}</p>}
    </div>
);

const EditLocation = props => {
    const { handleSubmit, show, onHide } = props;

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            // size="lg"
            centered >
            <div className="modal-staff">
                <div className="head">
                    <div>
                        <i class="fas fa-arrow-left" onClick={onHide}></i>
                        <h4> EDIT LOCATION</h4>
                    </div>
                </div>
                <Row className='modal-unit'>
                    <Col md={12} className="edit-product new-pro">
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={12} className="mt-4" style={{ marginBottom: "12px" }}>
                                    <label class="form-control-placeholders" for="name">Area <span>*</span></label>
                                    <Field
                                        name="area"
                                        type="text"
                                        component={textField}
                                        // placeholder="Enter Area *"
                                        required={true}
                                    />
                                </Col>

                                <Col md={12} className="mt-3">
                                    <label class="form-control-placeholders" for="name">Dimension </label>
                                    <Field
                                        name="dimensions"
                                        type="text"
                                        component={textField}
                                        maxLength={15}
                                        // placeholder="Enter dimension *"
                                        required={true}
                                    />
                                </Col>
                                <Col md={12} className="mt-3">
                                    <label class="form-control-placeholders" for="name">Drawing Reference Number</label>
                                    <Field
                                        name="drawingReferenceNumber"
                                        type="text"
                                        component={textField}
                                        maxLength={10}
                                        // placeholder="Enter drawingReferenceNumber"
                                        required={true}
                                    />
                                </Col>
                                <Col md={12} className="mt-3">
                                    <label class="form-control-placeholders" for="name">Set Count <span>*</span></label>
                                    <Field
                                        name="setCount"
                                        type="number"
                                        component={textField}
                                        // placeholder="Enter setCount"
                                        required={true}
                                    />
                                </Col>
                                <Col md={12} className="mt-3">
                                    <Field
                                        type="hidden"
                                        name="locationId"
                                        component={textField}
                                        // placeholder="Enter Unit Cost"
                                        required={true}
                                    />
                                </Col>
                            </Row>
                            <button type="submit" className="modal-btn">
                                Update Location
                            </button>
                        </form>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default reduxForm({
    form: 'EditLocationForm',
    enableReinitialize: true,
    validate
})(EditLocation)

