import { DatePicker } from "antd";
import axios from "axios";
import parse from 'html-react-parser';
import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Modal, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { baseUrl, qutationDateFormatV2, changePoDateFormat, exportToJson, displayCurrency, getBulkDownloadCsv, updateBulktable } from "../../actions";
import Art from "../../assets/images/art.svg";
import Csvn from "../../assets/images/csvn.jpg";
import Load from "../../assets/images/rou.gif";
import moment from "moment";



const Bulk = (props) => {

    const { loading, success_msg, bulkData, bulkDispatch, bulkUpdateDispatch } = props;

    const { show, uploadShow, uploadClose } = props;

    const [fileUploaded, setFileUploaded] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [csvName, setCsvName] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [date, setDate] = useState(null);

    //TODO: Csv Upload
    const handleChange = (e) => {
        setCsvName(e.target.files[0].name)
        var bodyFormData = new FormData();
        bodyFormData.append("file", e.target.files[0]);
        setFileUploaded(bodyFormData);
    };

    const uploadCSV = async () => {
        if (date === null) {
            toast("Please Select Effective date")
        } else if (fileUploaded !== null) {
            setIsSubmitted(true);
            axios({
                method: "POST",
                url: `${baseUrl}staff/product/uploadFileList`,
                data: fileUploaded,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
            }).then(function (response) {
                setIsSubmitted(false);
                if (response?.data?.status === 200) {
                    toast(response?.data?.data)
                    setTableData(response?.data?.data)
                }
            }, err => {
                setIsSubmitted(false);
                toast("Kindly Check and upload your csv format")
            });
        } else {
            toast("Please Select Csv")
        }
    };

    useEffect(() => {
        if (show === true) {
            setFileUploaded(null);
            setTableData(null);
            setDate(null);
        }
    }, [show])




    //TODO: Table Update value submit
    const handleCellEdit = (e, id) => {
        tableData.map((row) => {
            if (row.serialNo === id) {
                row.newPrice = e.target.textContent;
            }
            return null;
        });
    };

    const disabledPastDate = (current) => current && current < moment().startOf("day");

    const onDate = (e) => {
        if (e === null) {
            setDate(null)
        } else {
            setDate(qutationDateFormatV2(e._d))
        }
    }

    const handleSave = () => {
        if (date !== null) {
            let data = {
                "date": date,
                "tableDto": tableData.map((item) => {
                    return {
                        "productId": item?.productId,
                        "newPrice": Number(item?.newPrice) || null
                    }
                })
            }
            bulkUpdateDispatch(JSON.stringify(data))
        } else {
            toast('Please Select date')
        }
    };

    const download = () => {
        exportToJson(bulkData, "CSV File")
    }

    useEffect(() => {
        bulkDispatch();
    }, [])

    useEffect(() => {
        if (success_msg?.status === 200) {
            uploadClose();
            setDate(null);
        }
    }, [success_msg])


    return (
        <Fragment>
            <button className="rotate-img" onClick={uploadShow}>
                <img src={Art} alt=".." /> Price
            </button>
            <Modal
                show={show}
                size="lg"
                onHide={uploadClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <div className="modal-staff">
                    <div className="head">
                        <div>
                            <i class="fas fa-arrow-left" onClick={uploadClose}></i>
                            <h4>Upload Product</h4>
                        </div>
                        {tableData === null ?
                            <div>
                                <div className="pop-label-main">
                                    <label>Effective Date : </label>
                                    <DatePicker onChange={onDate} disabledDate={disabledPastDate} />
                                </div>
                            </div> : null}
                        {tableData === null ?
                            <span className="down-bulk" onClick={download}>
                                <a> <img src={loading ? Load : Art} alt=".." />Product CSV Download</a>
                            </span>
                            :
                            <h5 className="date-sign">Effective Date : {changePoDateFormat(date)}</h5>}
                    </div>
                    {tableData !== null ? null :
                        <Row className="upload-area-main">
                            <Col md={12}>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label className="upload-area">
                                        {fileUploaded !== null ? <><img src={Csvn} alt="---" /><h2>{csvName}</h2></> : <h1>Upload Your Product Price CSV</h1>}
                                    </Form.Label>
                                    <Form.Control onChange={handleChange} type="file" accept=".csv" />
                                </Form.Group>
                                {isSubmitted === false ? <button onClick={uploadCSV} disabled={isSubmitted} >Upload</button> : <button> Uploading.....</button>}
                            </Col>
                        </Row>}
                </div>
                {tableData !== null ?
                    <div className="bulk-pop-up-table">
                        <table class="table table-borderless" >
                            <thead>
                                <tr>
                                    <th>Article ID</th>
                                    <th>ProductName</th>
                                    <th className="txt-right">Current Price</th>
                                    <th className="txt-center">NewPrice</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData?.map((item) => (
                                    <tr key={item?.serialNo}>
                                        <td className="pop-item">{item?.articleId}</td>
                                        <td className="pop-item">
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.productName || "-")}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    {parse(item.productName || "-")}
                                                </span>
                                            </OverlayTrigger>
                                        </td>
                                        <td className="txt-right">{displayCurrency(item?.currentPrice)}</td>
                                        <td className="txt-center" contentEditable onBlur={(e) => handleCellEdit(e, item.serialNo)}>{item?.newPrice}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    : null}
                {tableData !== null ?
                    <div className="save-btn-pop upload-area-main">
                        {loading ? <button className="bulk-btn">Loading....</button> :
                            <button className="bulk-btn" onClick={handleSave}>Update</button>}
                    </div>
                    : null}

            </Modal>
        </Fragment>
    )
}


const mapStateToProps = (state) => {
    const { product } = state
    return {
        loading: product.loading,
        bulkData: product.bulkData,
        success_msg: product.success_msg
    }
}

const mapDispatchToProps = (dispatch) => ({
    bulkDispatch: (formData) => dispatch(getBulkDownloadCsv(formData)),
    bulkUpdateDispatch: (formData) => dispatch(updateBulktable(formData)),

})

export default connect(mapStateToProps, mapDispatchToProps)(Bulk)