import React, {useEffect} from "react";
import { Modal, Row } from "react-bootstrap";
import "../../../assets/css/popup.css";
import { Link } from "react-router-dom";

const  PreviewTemplate = (props) => {
    const { show, onHide, temp, title, templateId } = props;

    useEffect(() => {
        if(show === true) {
            let previewImageElement = document.getElementById("preview_img");
            previewImageElement.style.width = "50%";
            if(templateId === 1 || templateId === 2) {
                previewImageElement.style.border = "2px solid #000";
            }
        }
    })

    return (
        <>
            <Modal show={show} onHide={onHide} size="lg"
                aria-labelledby="contained-modal-title-vcenter" centered >
                <div className="modal-staff preview">
                    <div className="modal-staff head">
                        <div>
                            <button onClick={onHide}>
                                <i class="fas fa-arrow-left"></i>
                            </button>
                            <h4>{title}</h4>
                        </div>

                        <Link to={`/${props.authRole}/quotations/create-quotation/customerDetails/${props.templateId}/null`}>
                            <button className="select-template">
                                Select Template
                            </button>
                        </Link>
                    </div>
                    <Row className="preview-img" style={{ minHeight: "500px",}}>
                        <img id="preview_img" src={temp}/>
                    </Row>
                </div>
            </Modal>
        </>
    )
}


export default PreviewTemplate;