import { Button, Popover, DatePicker, Form, Row, Col, Input } from 'antd';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Card, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import filter from "../../../assets/images/filt.svg";
import { getReportOaList, filterDateFormat, displayCurrency, htmlTagRemove, shortStr, exportToJson, oaReportCsv } from '../../../actions';
import "../../../assets/css/button.css";
import "../../../assets/css/popup.css";
import Re from "../../../assets/images/green2.svg";
import Search from "../../../assets/images/search.svg";
import Art from "../../../assets/images/art.svg";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import OaConfirm from './oaConfirm';

const role = localStorage.getItem('userRole');
const OaReport = (props) => {

    const { oaAll, loading, oaData, oaListDispatch, oaCsvDispatch, csvData } = props;

    const [pageNo, setPageNo] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const [initValues, setInitValues] = useState({
        "size": 50,
        "pageNo": pageNo,
        "fromDate": null,
        "toDate": null,
        "sizeOfPage": 100,
        "searchKey": searchKey
    })

    const onFetchListTickets = (values) => {
        oaListDispatch(values)
    }

    const onChange = () => {
        initValues.pageNo = pageNo + 1;
        setInitValues(initValues);
        setPageNo(pageNo + 1);
    }

    const searchHandler = (e) => {
        initValues.searchKey = e.target.value;
        let data = { ...initValues };
        data.searchKey = e.target.value;
        data.pageNo = e.target.value !== "" ? 0 : initValues.pageNo;
        setInitValues(data);
        setSearchKey(e.target.value);
        setPageNo(0);
    }

    const sortHandler = (values) => {
        let data = { ...initValues };
        data.sortField = values;
        if (initValues.sortMethod === 'asc') {
            data.sortMethod = 'desc';
        } else {
            data.sortMethod = 'asc';
        }
        setInitValues(data);
        onFetchListTickets(data);
    }

    useEffect(() => {
        onFetchListTickets(initValues);
        oaCsvDispatch({
            "fromDate": null,
            "toDate": null,
            "searchKey1": searchKey
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNo, searchKey]);






    const filterRef = useRef();
    const [filterform] = Form.useForm();


    const [fromdate, setFromdate] = useState(null);
    const [todate, setTodate] = useState(null);

    const filterHandler = () => {
        let data = {
            "fromDate": filterDateFormat(fromdate),
            "toDate": filterDateFormat(todate)
        }
        setInitValues(data);
        onFetchListTickets(data)
        setPageNo(0);
        window.scrollTo(0, 0);
    }


    const resetHandler = () => {
        let data = {
            "fromDate": "",
            "toDate": "",
        }
        setInitValues(data);
        onFetchListTickets(data);
        setPageNo(0);
        window.scrollTo(0, 0);
    }

    const fromHandler = (val) => {
        let item = val === null ? "" : (val._d)
        setFromdate(item)
    }
    const toHandler = (val) => {
        let item = val === null ? "" : (val._d)
        setTodate(item)
    }


    const disabledDate = (current) => current && current > moment().endOf("day");
    const checkDisabledDate = (current) => (current && current > moment().endOf("day")) || moment(fromdate).startOf("day") > current;



    const content = (
        <div className="drop-data">
            <Row>
                <Col md={24} xs={24}>
                    <Form
                        form={filterform}
                        ref={filterRef}
                        name="filter_info"
                        onFinish={filterHandler}
                        layout="vertical">
                        <Form.Item label="From Date" name="fromDate">
                            <DatePicker onChange={fromHandler} disabledDate={disabledDate} />
                        </Form.Item>
                        <Form.Item label="To Date" name="toDate">
                            <DatePicker onChange={toHandler} disabledDate={checkDisabledDate} />
                        </Form.Item>
                        <Row gutter={[24, 0]}>
                            <Col md={12}>
                                <Form.Item>
                                    <Button className="service-btn" type="primary" htmlType="submit" block>Apply </Button>
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item>
                                    <Button className="service-btn reset" type="primary"
                                        onClick={() => {
                                            resetHandler();
                                            filterRef.current.resetFields();
                                        }} block>Reset </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );


    const [showPopUp, setShowPopUp] = useState(false);
    const [data, setData] = useState(null);

    const onShow = (val) => {
        setData(val);
        setShowPopUp(true);
    }

    const onClose = () => {
        setShowPopUp(false);
    }

    const csv = () => {
        exportToJson(csvData, "CSV OA Report List")
    }


    return (
        <>
            <Fragment>
                <OaConfirm show={showPopUp} onHide={onClose} data={data} onFetchListTickets={onFetchListTickets} initValues={initValues} />
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <div className="page-head-split">
                                <div className="head-colums">
                                    <div className="head-part-tab">
                                        <img src={Re} alt="..." className="head-imgs" />
                                        <h5>PSO</h5>
                                    </div>
                                    <div className="tab-links">
                                        <Link to={"/" + role + "/oa"}>OA Details</Link>
                                        <Link to={"/" + role + "/oa/oa_report"} className="active">PSO</Link>
                                        <Link to={"/" + role + "/oa/oaConfirmPage"}>MC</Link>
                                        <Link to={"/" + role + "/oa/oaDownload"}>MC Report</Link>
                                        <Link to={"/" + role + "/oa/ms"}>MS</Link>
                                    </div>
                                    <div className="customer-search oa-search">
                                        <span><img src={Search} alt=".." className="search-imgs" /></span>
                                        <Input type="search" onChange={searchHandler} placeholder="Search" className="main-search" />
                                    </div>
                                    <div className='filter-background new-filter-set-report'>
                                        <Popover content={content} placement="bottomLeft" trigger="click"   >
                                            <button className="filter-button" style={{ marginTop: "10px" }}>
                                                <img src={filter} alt="..." width="18px" style={{ paddingRight: "5px" }} /> Filter
                                            </button>
                                        </Popover>
                                    </div>
                                    <button className="download_btn" onClick={csv}>
                                        <img src={Art} alt=".." /> CSV
                                    </button>

                                </div>
                            </div>
                            <div className="infinite-table" id="scrollableDiv">
                                <InfiniteScroll
                                    dataLength={oaAll?.content?.length}
                                    next={onChange}
                                    hasMore={oaAll?.content?.length < oaData?.totalElements}
                                    loader={<p className='txt-center'><h4>Loading...</h4></p>}
                                    endMessage={<p className='txt-center'>{oaAll?.content?.length === 0 ? <p className='no-data-hg'> <b >{loading ? <Spinner /> : "No Data Found"}</b></p> : <b>Yay! You have seen it all</b>}</p>}
                                    refreshFunction={() => { }}
                                    pullDownToRefresh
                                    scrollableTarget="scrollableDiv"
                                    pullDownToRefreshThreshold={60}
                                    pullDownToRefreshContent={<h3 className='txt-center'>&#8595; Pull down to refresh</h3>}
                                    releaseToRefreshContent={<h3 className='txt-center'>&#8593; Release to refresh</h3>}
                                >
                                    <table class="table table-borderless uniq-table">
                                        <thead>
                                            <tr>
                                                <th>PO No</th>
                                                <th>PO Date</th>
                                                <th>OA No</th>
                                                <th>OA Date</th>
                                                <th>Customer</th>
                                                <th>Project</th>
                                                <th>Art No</th>
                                                <th>Product Name</th>
                                                <th className='txt-right'>PO Qty</th>
                                                <th className='txt-right'>Unit Cost</th>
                                                <th className='txt-right'>OA Qty</th>
                                                <th className='txt-right'>Bal Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {oaAll?.content.map((item, a) => (
                                                <tr key={a} onClick={() => onShow(item)} className={!item?.specialPrice ? "td-size" : "shade"}>
                                                    <td>{shortStr(item?.poNumber)}</td>
                                                    <td>{filterDateFormat(item?.poGeneratedDate)}</td>
                                                    <td>
                                                        <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {item?.oaNumber}</Tooltip>}>
                                                            <span className="d-inline-block value-add">
                                                                {item?.oaNumber}
                                                            </span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>{filterDateFormat(item?.oaDate)}</td>
                                                    <td>
                                                        <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {item?.customerName}</Tooltip>}>
                                                            <span className="d-inline-block value-add">
                                                                {item?.customerName}
                                                            </span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {item?.projectName}</Tooltip>}>
                                                            <span className="d-inline-block value-add">
                                                                {item?.projectName}
                                                            </span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>{item?.articleId}</td>
                                                    <td className="product_name">
                                                        <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {htmlTagRemove(item?.productName) || "-"}</Tooltip>}>
                                                            <span className="d-inline-block">
                                                                {htmlTagRemove(item?.productName) || "-"}
                                                            </span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td className='txt-center'>{item?.poQuantity}</td>
                                                    <td className='txt-right'>{displayCurrency(item.unitPrice)}</td>
                                                    <td className='txt-center'>{item.oaQuantity}</td>
                                                    <td className='txt-center'>{item?.remainingQuantity}</td>
                                                </tr>))}
                                        </tbody>
                                    </table>
                                </InfiniteScroll>
                            </div>
                        </Card>
                    </div>
                </div>
            </Fragment>
        </>
    );
}

const mapStateToProps = (state) => {
    const { oa } = state
    return {
        oaData: oa.oaAll,
        oaAll: { content: oa.newData },
        csvData: oa.csvData,
        loading: oa.loading
    }
}

const mapDispatchToProps = (dispatch) => ({
    oaListDispatch: (formData) => dispatch(getReportOaList(formData)),
    oaCsvDispatch: (formData) => dispatch(oaReportCsv(formData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OaReport)

