import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Input, Row , Select} from "antd";
import { Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import plus from "../../../assets/images/pl.svg";
import ProductForm from "./productForm";
import ViewProducts from "./viewProducts";
import './style.css';
import { connect } from "react-redux";
import { clearAddOrEditProductsResponseAction, clearInitialPackageResponse, editPackageAction, getPackageProductDropdownAction, getProductsForPackageAction, initialCreatePackage } from "../../../actions/packageActions";
import { clearSingleProduct } from "../../../actions";
import { toast } from "react-toastify";
import { checkDataIsEmpty } from "../../../helpers/checkDataIsEmpty";

const { Option } = Select;

const PackageForm = (props) => {
    const {initialPacakageData, onInitialPackageCreationDispatch, loading, onClearInitialPackageDispatch} = props;
    const {onGetProductsByPackageIdDispatch, reset, onClearAddOrEditProductDispatch, showForm, packageDetails, packageDropdownData, triggerPackageDropdown} = props;
    const {onClearSingleProductDispatch, onEditPackageDispatch, updateLoading, editPackageResponse, productsList} = props;
    const role = localStorage.getItem('userRole');
    const location = useLocation();
    let history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const packageId = queryParams.get('pid');
    const createId = queryParams.get("create");

    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [searchKey, setSearchKey] = useState(false);
    const [packageNameError, setPackageNameError] = useState("");
    const [unitPriceError, setUnitPriceError] = useState("");
    const [formData, setFormData] = useState({ articleId: '', noOfProducts: '', packageName: "", unitPrice: null });
    const [selectedProduct, setSelectedProduct] = useState(null);

    const resetForm = (productId) => {
        setSelectedProduct(null);
        onClearSingleProductDispatch();
        if(productId) {
            setOpen(true);
            onGetProductsByPackageIdDispatch(packageId);
            onClearAddOrEditProductDispatch();
        }
    }

    const editProductByProductId = (item) => {
        history.push(`?pid=${packageId}&productId=${item?.productId}&packageProductId=${item?.packageProductId}`);
        setSelectedProduct(item);
    }

    const handleShow = () => {
        setShow(true);
        setOpen(false);
    }

    const handleClose = () => {
        onClearInitialPackageDispatch();
        setShow(false);
        setOpen(false);
        if(checkIsFromProduct()) {
            history.push(`/${role}/product`);
        } else {
            history.push(`/${role}/packages`);
        }
        setFormData({})
        reset();
    }

    const checkIsFromProduct = () => {
        return createId === "1"
    }

    const handleChange = (e, field) => {
        const { value } = e.target;
        if(checkDataIsEmpty(value) === false) {
            setUnitPriceError("");
        } else {
            setUnitPriceError("Unit Price Required!");
        }
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const validateForm = () => {
        let isValid = true;
    
        // Validate packageName
        if (checkDataIsEmpty(formData?.packageName) === true) {
            setPackageNameError("Package Name Required!");
            isValid = false;
        } else {
            setPackageNameError("");
        }
    
        // Validate unitPrice
        if (checkDataIsEmpty(formData?.unitPrice) === true) {
            setUnitPriceError("Unit Price Required!");
            isValid = false;
        } else {
            setUnitPriceError("");
        }
    
        return isValid;
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (validateForm() === false) {
            return;
        }
        
        if (packageId) {
            let jsonBody = {
                packageId: packageId,
                articleId: formData.articleId,
                packageName: formData.packageName,
                unitPrice: formData.unitPrice
            };
            onEditPackageDispatch(jsonBody);
        } else {
            let jsonBody = {
                articleId: formData.articleId,
                packageName: formData.packageName,
                unitPrice: formData.unitPrice
            };
            onInitialPackageCreationDispatch(jsonBody);
        }
    };
    

    useEffect(() => {
        if(packageId !== null && packageId !== undefined && checkDataIsEmpty(initialPacakageData?.data?.packageId)) {
            handleShow();
            onGetProductsByPackageIdDispatch(packageId);
            openTable();
        }
    },[packageId])

    useEffect(() => {
        if(loading === false) {
            if(checkDataIsEmpty(initialPacakageData?.data?.packageId) === false) {
            history.push(`?${checkIsFromProduct() ? "create=1&" : ""}id=${formData?.articleId}&pid=${initialPacakageData?.data?.packageId}`)
        }
        }
    },[loading])

    useEffect(() => {
        if(editPackageResponse) {
            toast(editPackageResponse?.data);
        }
    }, [editPackageResponse])

    useEffect(() => {
        if(productsList) {
           setFormData({ articleId: productsList?.articleId, packageName: productsList?.packageName, unitPrice: productsList?.unitPrice })
        }
    }, [productsList])

    useEffect(() => {
      if(showForm) {
        setShow(showForm);
      }
    }, [showForm])

    useEffect(() => {
        if(createId && checkDataIsEmpty(packageDetails) === false) {
            setFormData({ articleId: packageDetails?.articleId, packageName: packageDetails?.packageName, unitPrice: packageDetails?.unitPrice })
        }
    }, [createId])

    //  View Table
    const openTable = () => {
        setOpen(prev => !prev)
    }

    const handleSearchProducts = (value) => {
        setSearchKey(value);
    }

    useEffect(() => {
        triggerPackageDropdown("");
    }, [])

    useEffect(() => {
        if (searchKey) {
            triggerPackageDropdown(searchKey);
        }
    }, [searchKey])

    const onSelectProduct = (value, event) => {
        setPackageNameError("");
        setUnitPriceError("");
        let singlePackageDetails =  packageDropdownData.find(item => item.articleId === value)
        setFormData({ articleId: singlePackageDetails?.articleId, packageName: singlePackageDetails?.productName, unitPrice: singlePackageDetails?.unitCost })
    }


    return (
        <Fragment>
            <button className="product-add-staffs" onClick={handleShow}>
                <img src={plus} alt="" /> Create Package
            </button>

            <Modal show={show} onHide={handleClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="modal-staff">
                    <div className="head">
                        <div>
                            <i class="fas fa-arrow-left" onClick={handleClose}></i>
                            <h4>{packageId ? "Edit" : "Create"} Package</h4>
                        </div>
                    </div>
                    <div className="add-padd-top pack-add-bottom" >
                        <form className="floating-label-form" onSubmit={handleSubmit}>
                            <Row gutter={[16, 16]}>
                                <Col md={4}>
                                    <div className={'floating-label-container'}>
                                        <label className={'form-control-placeholder-pack'}>Article ID</label>
                                        <Input required={true} maxLength={15}
                                            value={formData.articleId}
                                            onChange={(e) => handleChange(e, 'articleId')}
                                            disabled
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className={'floating-label-container'}>
                                        <label className={'form-control-placeholder-pack'}>Package Name <span>*</span></label>
                                        <Select
                                        style={{width: "100%"}}
                                        showSearch
                                        onSearch={handleSearchProducts}
                                        onChange={onSelectProduct}
                                        value={formData.packageName}
                                        optionFilterProp="children"
                                        disabled={checkIsFromProduct() || packageId !== null}
                                         >
                                        {packageDropdownData?.map((option, a) => (
                                            <Option key={a} value={option.articleId} label={option.productName}>
                                                {option.productName}
                                            </Option>
                                        ))}
                                    </Select>
                                    {packageNameError !== "" ? <p>{packageNameError}</p> : null}
                                        
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className={'floating-label-container'}>
                                        <label className={'form-control-placeholder-pack'}>Unit Price <span>*</span></label>
                                        <Input
                                            value={formData.unitPrice}
                                            onChange={(e) => handleChange(e, 'unitPrice')}
                                            type="number"
                                            step={"any"}
                                        />
                                        {unitPriceError !== "" ? <p>{unitPriceError}</p> : null}
                                    </div>
                                </Col>
                                <Col md={4}> <Button loading={updateLoading} htmlType="submit" className="sub-btn" block>{(id || packageId) ? "Update" : "Create"} Package</Button></Col>
                            </Row>
                        </form>
                    </div>
                </div>
                <ViewProducts open={open || packageId} id={id} edit={editProductByProductId} reset={resetForm} productItem={selectedProduct}/>
            </Modal>
        </Fragment >
    );
}

const  mapStateToProps = (state) => {
    const {packages} = state;
    return {
        packageDropdownData: packages.packageDropdown,
        initialPacakageData: packages.initialPacakageData,
        loading: packages.loading,
        updateLoading: packages.editLoading,
        editPackageResponse: packages.editPackage,
        productsList: packages.productsList?.data,
    }
}

const mapDispatchToProps = (dispatch) => ({
    onInitialPackageCreationDispatch: (formData) => dispatch(initialCreatePackage(formData)),
    onClearInitialPackageDispatch: () => dispatch(clearInitialPackageResponse()),
    onGetProductsByPackageIdDispatch: (id) => dispatch(getProductsForPackageAction(id)),
    onClearAddOrEditProductDispatch: () => dispatch(clearAddOrEditProductsResponseAction()),
    onClearSingleProductDispatch: () => dispatch(clearSingleProduct()),
    onEditPackageDispatch: (formData) => dispatch(editPackageAction(formData)),
    triggerPackageDropdown: (searchKey) => dispatch(getPackageProductDropdownAction(searchKey))
})
export default connect(mapStateToProps, mapDispatchToProps)(PackageForm);