import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { getProductList, getProductById } from '../../actions';
import { Row, Col, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
// import validate from './Validate';
import plus from "../../assets/images/pl.svg";

const renderField = ({ input, placeholder, maxLength, disabled, type, readOnly, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input {...input} placeholder={placeholder} disabled={disabled} type={type} className="form-control" maxLength={maxLength} readOnly={readOnly} />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

class CheckAvailability extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
        }
        this.productNameChange = this.productNameChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
    }
    productNameChange(e) {
        this.props.products_list?.map((item) => {
            if (item?.productName === e[0]) {
                // setProduct(item);
                this.props.dispatch(getProductById(item?.productId));
                // this.props.dispatch(getStockProduct());
            }
        });
    }
    inputChange(text) {
        this.props.dispatch(getProductList(0, text, {}, {}, 50));
    }

    render() {
        const { error, show, onHide, reset, handleShow, products_list, handleSubmit, initialValues, list } = this.props;

        return (
            <>
                <button className="dashboard-btn" onClick={handleShow}>
                    <img src={plus} alt="" /> Check Availability </button>
                <Modal
                    show={show}
                    size="lg"
                    onHide={() => {
                        onHide();
                        reset();
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-staff">
                        <div className="head">
                            <div>
                                <i class="fas fa-arrow-left" onClick={() => {
                                    onHide();
                                    reset();
                                }}></i>
                                <h4>CHECK AVAILABILITY</h4>
                            </div>
                        </div>
                        <Row>
                            <form onSubmit={handleSubmit} className="customers">
                                <Row>
                                    <Col md={12}>
                                        <label class="form-control-placeholder type-form" for="name" >Product Name <span>*</span></label>
                                        {products_list?.length >= 0 ? <Typeahead
                                            onChange={this.productNameChange}
                                            id="async-example"
                                            labelKey={products_list.map(item => item.productName)}
                                            onInputChange={this.inputChange}
                                            options={products_list.map(item => item.productName)}
                                        // placeholder="Choose a Product Name *"
                                        /> : null}
                                        <div className="triangle-bottom" style={{ marginTop: "-22px" }}></div>
                                    </Col>
                                    <Col md={3}>
                                        <label class="form-control-placeholder" for="name" >Article ID</label>
                                        <Field name="articleId" type="text" component={renderField} maxLength="10" disabled={true} />
                                    </Col>
                                    <Col md={3}>
                                        <label class="form-control-placeholder" for="name" >Unit Name</label>
                                        <Field name="unitName" type="text" component={renderField} disabled={true} />
                                    </Col>
                                    <Col md={3}>
                                        <label class="form-control-placeholder" for="name" >In Stock</label>
                                        <Field name="inStock" type="text" component={renderField} disabled={true} />
                                    </Col>
                                    <Col md={3}>
                                        <label class="form-control-placeholder" for="name" >Total Stock</label>
                                        <Field name="totalStock" type="text" component={renderField} disabled={true} />
                                    </Col>
                                    {initialValues?.projectProductStockResDtoList !== null && initialValues?.projectProductStockResDtoList.map((loc) => {
                                        return (
                                            <>
                                                <Col md={3}>
                                                    <label></label>
                                                    <Form.Control
                                                        type="text"
                                                        name="purchaseOrderId"
                                                        value={loc.purchaseOrderId}
                                                        placeholder="Enter Area"
                                                        readOnly
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <label></label>
                                                    <Form.Control
                                                        type="text"
                                                        name="projectName"
                                                        value={loc.projectName}
                                                        placeholder="Enter Area"
                                                        readOnly
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <label></label>
                                                    <Form.Control
                                                        type="text"
                                                        name="requiredQty"
                                                        value={loc.requiredQty}
                                                        placeholder="Enter Area"
                                                        readOnly
                                                    />
                                                </Col>
                                            </>
                                        )
                                    })}
                                    {error && <strong>{error}</strong>}
                                </Row>
                            </form>
                        </Row>
                    </div>
                </Modal >
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        initialValues: state.product.singleProduct,
        products_list: state.product.products_list,
    }
}

const checkForm = reduxForm({
    form: "CheckAvilablity",
    destroyOnUnmount: false,
    enableReinitialize: true,
    // validate
})(CheckAvailability);

export default connect(mapStateToProps)(checkForm);
