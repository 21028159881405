import React from "react";
import { Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import DashBoard from "../assets/images/dash.svg";
import Product from "../assets/images/red1.svg";
import Quo from "../assets/images/orange1.svg";
import Cust from "../assets/images/green1.svg";
import Reports from "../assets/images/violet1.svg";
import Bulp from "../assets/images/yellow1.svg";
import Inward from "../assets/images/blue1.svg";
import Purchase from "../assets/images/green2.svg";
import Package from "../assets/images/package.png";
import Vendor from "../assets/images/vendor-one.png";
import Boq from "../assets/images/boq-one.png";
import Outward from "../assets/images/out-one.png";



const role = localStorage.getItem('userRole');

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.appLogout = this.appLogout.bind(this);
  }
  appLogout(e) {
    localStorage.clear();
    window.location = '/';
  }

  render() {
    return (
      <Card className="side-bar">
        <NavLink to={"/" + role + "/dashboard"} activeClassName="sky-blue-active" tooltip="Dashboard" flow="right">
          <li>
            <img src={DashBoard} alt="..." />
          </li>
        </NavLink>
        <NavLink to={"/" + role + "/customers"} activeClassName="green-active" tooltip="IDS Directory" flow="right">
          <li>
            <img src={Cust} alt="..." width="100%" />
          </li>
        </NavLink>
        <NavLink to={"/" + role + "/product"} activeClassName="red-active" tooltip="Products" flow="right">
          <li>
            <img src={Product} alt="..." />
          </li>
        </NavLink>
        {/* {role !== "staff" ? <NavLink to={"/" + role + "/staff"} activeClassName="active">
          <li>
            <img src={Staff} alt="..." />
            Staffs
          </li>
        </NavLink> : null} */}
        <NavLink to={"/" + role + "/projects"} activeClassName="yellow-active" tooltip="Projects" flow="right">
          <li>
            <img src={Bulp} alt="..." />
          </li>
        </NavLink>
        <NavLink to={"/" + role + "/quotations"} activeClassName="active" tooltip="Quotations" flow="right">
          <li>
            <img src={Quo} alt="..." />
          </li>
        </NavLink>
        <NavLink to={"/" + role + "/bill_of_quantity"} activeClassName="greens-active" tooltip="Bill Of Quantity" flow="right">
          <li>
            <img src={Boq} alt="..." style={{ width: "22px", height: "22px" }} />
          </li>
        </NavLink>
        <NavLink to={"/" + role + "/vendor_po"} className="pinky" activeClassName="pinky-active" tooltip="Purchase Order" flow="right">
          <li>
            <img src={Vendor} alt="..." style={{ width: "22px", height: "22px" }} />
          </li>
        </NavLink>
        <NavLink to={"/" + role + "/inward"} activeClassName="ink-blue-active" tooltip="Inward" flow="right">
          <li>
            <img src={Inward} alt="..." />
          </li>
        </NavLink>
        <NavLink to={"/" + role + "/outward"} activeClassName="gr-active" tooltip="Outward" flow="right">
          <li>
            <img src={Outward} alt="..." style={{ width: "25px", height: "25px", marginLeft: "1px" }} />
          </li>
        </NavLink>
        <NavLink to={"/" + role + "/sale_executive"} activeClassName="violet-active" tooltip="Reports" flow="right">
          <li>
            <img src={Reports} alt="..." />
          </li>
        </NavLink>
        <NavLink to={"/" + role + "/oa"} activeClassName="active" tooltip="OA Reports" flow="right">
          <li>
            <img src={Purchase} alt="..." />
          </li>
        </NavLink>
        <NavLink to={"/" + role + "/packages"} activeClassName="package-active" tooltip="Packages" flow="right">
          <li>
            <img src={Package} alt="..."/>
          </li>
        </NavLink>
      </Card>
    )
  }
}

export default Sidebar;