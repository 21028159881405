import { packageConstants } from "../constants";

export const initialCreatePackage = (formData) => {
    return {
        type : packageConstants.GET_INITIAL_PACKAGE_ID,
        formData
    }
}

export const editPackageAction = (formData) => {
    return {
        type: packageConstants.EDIT_PACKAGE,
        formData
    }
} 

export const getPackages = (formData) => {
    return {
        type: packageConstants.GET_PACKAGE_LIST,
        formData
    }
}

export const addPackageProductAction = (formData) => {
    return  {
        type: packageConstants.ADD_PACKAGE_PRODUCT,
        formData
    }
}

export const editPackageProductAction = (formData) => {
    return {
        type: packageConstants.EDIT_PACKAGE_PRODUCT,
        formData
    }
}

export const getProductsForPackageAction = (id) => {
    return {
        type: packageConstants.GET_PRODUCTS_FOR_PACKAGE,
        id
    }
}

export const clearInitialPackageResponse = () => {
    return {
        type: packageConstants.CLEAR_INITIAL_PACKAGE
    }
}

export const clearAddOrEditProductsResponseAction = () => {
    return {
        type: packageConstants.CLEAR_ADD_OR_EDIT_PRODUCT
    }
}

export const clearAddOrEditPackageResponseAction = () => {
    return {
        type: packageConstants.CLEAR_ADD_OR_EDIT_PACKAGE
    }
}

export const getPackageProductDropdownAction = (searchKey) => {
    return {
        type: packageConstants.GET_PACKAGE_PRODUCT_DROPDOWN,
        searchKey
    }
}

export const clearSinglePackageDropdown = () => {
    return {
        type: packageConstants.CLEAR_SINGLE_PACKAGE_DROPDOWN
    }
}

export const getProductDropdown = (searchKey) => {
    return {
        type: packageConstants.GET_PRODUCT_DROPDOWN,
        searchKey
    }
}