import React from "react";
import { Row, Col, Card, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Field, reduxForm, FieldArray, SubmissionError, reset } from "redux-form";
import { connect } from "react-redux";
import back from "../../../../assets/images/back (3).png";
import clone from "../../../../assets/images/clone1.svg";
import del from "../../../../assets/images/trash_del.svg";
import plus from "../../../../assets/images/bx-plus (2).svg";
import Delete from "../../../../assets/images/ux.svg";
import Edit from "../../../../assets/images/ui.svg";
import Navbar from "../../../../layouts/Navbar";
import Sidebar from "../../../../layouts/Sidebar";
import Orange from "../../../../assets/images/green2.svg";
import { toast } from "react-toastify";
import validate from "../validate";
import {
    getLocationListOfQuotations,
    editLocationToQuotation,
    addLocationToQuotation,
    deleteLocationToQuotation,
    getQutation,
    addIgnoreFields,
    changeTitleStr,
    cloneLocation
} from "../../../../actions";
import EditLocation from "./EditLocation";
const role = localStorage.getItem('userRole');


const textField = ({ input, name, label, placeholder, defaultValue, disabled, type, maxLength, meta: { touched, error, warning } }) => (
    <Form.Group className="mb-3" controlId={label}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
            {...input}
            name={name}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            defaultValue={defaultValue}
            maxLength={maxLength}
        />
        {touched && error && <p className="require">{error}</p>}
    </Form.Group>
)
class Location extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            empty: "",
            ignoredFields: [],
            location: [],
            deletePopup: false,
            deletedId: null,
            clonePopup: false,
            locationId: ""
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.renderLocation = this.renderLocation.bind(this);
        this.hideColumnHandler = this.hideColumnHandler.bind(this);
        this.addLocationHandler = this.addLocationHandler.bind(this);
        this.editHandler = this.editHandler.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);
        this.cloneHandler = this.cloneHandler.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }
    handleShow(item) {
        this.setState({
            show: true,
            location: item
        })
    }
    handleClose() {
        this.setState({
            show: false,
        })
    }
    editHandler(val) {
        if (!val.area) {
            throw new SubmissionError({
                area: 'Please fill the field *',
            })
        } else if (!val.setCount) {
            throw new SubmissionError({
                setCount: 'Please fill the field *'
            })
        } else if (val.setCount && /[^0-9 ]/i.test(val.setCount)) {
            throw new SubmissionError({
                setCount: 'Enter Valid digits'
            })
        } else if (val.setCount == 0) {
            throw new SubmissionError({
                setCount: 'Please enter a value greater than zero'
            })
        }
        if (Number(val.setCount) > 0) {
            let formData = {
                "locationId": val.locationId,
                "area": val.area,
                "dimension": val.dimensions,
                "drawingReferenceNumber": val.drawingReferenceNumber,
                'quotationId': this.props.match.params.quotId,
                "setCount": val.setCount
            };
            this.props.dispatch(editLocationToQuotation(formData))
        } else {
            toast("Setcount must be greater than zero");
        }
    }
    deleteHandler(deleteId) {
        this.setState({
            deletedId: deleteId,
            deletePopup: true,
        });
    }
    cloneHandler(id) {
        this.setState({
            locationId: id,
            clonePopup: true,
        });
    }
    hideColumnHandler(event) {
        const target = event.target;
        var value = target.value;
        let fields = this.state.ignoredFields;
        if (fields.includes(value)) {
            let newColumns = fields.filter(item => item !== value);
            this.setState({
                ignoredFields: newColumns
            });
        } else {
            fields.push(value);
            this.setState({
                ignoredFields: fields
            });
        }
    }
    prevPage() {
        const { history } = this.props;
        history.push(`/${role}/quotations/${this.props.match.params.add}/contentBody/${this.props.match.params.id}/${this.props.match.params.quotId}`)
    }
    nextPage() {
        const { history } = this.props;
        // if (this.props.quoteLocations?.length === 0) {
        //     toast("Please add atleast one Location");
        //     return false;
        // }
        setTimeout(() => {
            let data = {
                quotationId: this.props.match.params.quotId,
                ignoredFields: this.state.ignoredFields,
            }
            this.props.dispatch(addIgnoreFields(data));
            history.push(`/${role}/quotations/${this.props.match.params.add}/quotationBody/${this.props.match.params.id}/${this.props.match.params.quotId}`)
        }, 500);
    }
    renderLocation = ({ fields, meta: { error, submitFailed } }) => {
        return (
            <div className="qutation-page">
                <Row>
                    <Col md={11}>
                        <Row className="new-colo">
                            <Col md={4}>
                                <label className="colorss">Enter Area <span>*</span></label>
                            </Col>
                            <Col md={3}>
                                <label className="colorss">Area Dimensions</label>
                            </Col>
                            <Col md={3}>
                                <label className="colorss">Enter Dwg Ref Number</label>
                            </Col>
                            <Col md={2}>
                                <label className="colorss">Set Count <span>*</span></label>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.props.quoteLocations ?? this.props.quoteLocations?.length > 0 ? this.props.quoteLocations?.map((loc, index) => {
                    return (
                        <Row key={index}>
                            <Col md={11}>
                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-3" controlId="enterarea">
                                            <Form.Control
                                                type="text"
                                                disabled
                                                name="area"
                                                value={loc.area}
                                                placeholder="Enter Area"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="enterdimention">
                                            <Form.Control
                                                type="text"
                                                name="dimensions"
                                                disabled
                                                value={loc.dimensions}
                                                maxLength={15}
                                                placeholder="Area Dimensions"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="enterdwg">
                                            <Form.Control
                                                type="text"
                                                name="drawingReferenceNumber"
                                                disabled
                                                value={loc.drawingReferenceNumber}
                                                placeholder="Enter Dwg Ref Number"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group className="mb-3" controlId="entercount">
                                            <Form.Control
                                                type="text"
                                                disabled
                                                name="setCount"
                                                value={loc.setCount}
                                                placeholder="Set Count" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2} style={{ display: "none" }}>
                                        <Form.Group className="mb-3" controlId="entercount">
                                            <Form.Control type="text"
                                                defaultValue={loc.locationId}
                                                disabled
                                                placeholder="locationId" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1} className="loc_actions">
                                <img src={Edit} onClick={(e) => this.handleShow(loc)} alt=".." className="fog-edit" />
                                <img src={clone} alt=".." onClick={() => this.cloneHandler(loc.locationId)} />
                                <img src={Delete} alt=".." className="fog-edits" onClick={(e) => this.deleteHandler(loc.locationId)} />
                            </Col>
                        </Row>
                    )
                }) : null}
                {fields?.map((member, index) => (
                    <Row key={index}>
                        <Col md={11}>
                            <Row className="new-colo">
                                <Col md={4}>
                                    <Field
                                        name={`${member}.area`}
                                        type="text"
                                        component={textField}
                                        // label="Enter Area *"
                                        placeholder="Enter Area  *"
                                    />
                                </Col>
                                <Col md={3}>
                                    <Field
                                        name={`${member}.dimension`}
                                        type="text"
                                        component={textField}
                                        // label="Area Dimensions "
                                        maxLength={15}
                                        placeholder="Area Dimensions"
                                    />
                                </Col>
                                <Col md={3}>
                                    <Field
                                        name={`${member}.drawingReferenceNumber`}
                                        type="text"
                                        component={textField}
                                        maxLength={10}
                                        // label="Enter Dwg Ref Number "
                                        placeholder="Enter Dwg Ref Number "
                                    />
                                </Col>
                                <Col md={2}>
                                    <Field
                                        name={`${member}.setCount`}
                                        defaultValue="1"
                                        type="text"
                                        component={textField}
                                        // label="Set Count *"
                                        placeholder="Set Count *"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1} className="pos-rel">
                            <button type="submit" className="modal-btn foo plus" >
                                <img src={plus} alt="" />
                            </button>
                        </Col>
                        <Col md={1} className="pos-rel">
                            {/* <div className="delete" onClick={(e) => this.deleteHandler(loc.locationId)}>
                            <i className="fas fa-trash"></i>
                        </div> */}
                        </Col>
                    </Row>
                ))}
                <Row>
                    <Col md={2}>
                        <button onClick={this.prevPage} className="cancel-btn  new-btn" >
                            <img src={back} style={{ marginRight: "10px" }} />
                            Back
                        </button>
                    </Col>
                    <Col md={7}></Col>
                    <Col md={3}> <button type="submit" onClick={this.nextPage} className="modal-btn save new-btn">Save And Proceed</button></Col>
                    {submitFailed && error && <span>{error}</span>}
                </Row>
            </div>
        )
    };
    addLocationHandler(formData, dispatch) {
        let locations = formData.locations[0];
        if (locations.area !== "" && locations.setCount !== "") {
            let matrix = {
                'area': locations.area,
                'dimension': locations.dimension,
                'drawingReferenceNumber': locations.drawingReferenceNumber,
                'quotationId': this.props.match.params.quotId,
                'setCount': locations.setCount
            }
            this.props.dispatch(addLocationToQuotation(matrix));
            dispatch(reset("quotationLocationFormRedux"));
        }
        else {
            toast.error("Validation Error !", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.addLocationSuccess !== this.props.addLocationSuccess) {
            this.setState({
                addLocationSuccess: this.props.addLocationSuccess?.data,
            });
            if (this.props.addLocationSuccess?.status === 200) {
                // toast(this.props?.addLocationSuccess?.message);
                this.props.dispatch(getLocationListOfQuotations(this.props.match.params.quotId));
            } else {
                toast(this.props?.addLocationSuccess?.data);
            }
        } if (prevProps.editLocationSuccess !== this.props.editLocationSuccess) {
            this.setState({
                editLocationSuccess: this.props.editLocationSuccess?.data,
                show: false
            });
            if (this.props.editLocationSuccess?.data !== "") {
                toast(this.props?.editLocationSuccess?.data);
            }
            this.props.dispatch(getLocationListOfQuotations(this.props.match.params.quotId));
        } if (prevProps.deleteLocationSuccess !== this.props.deleteLocationSuccess) {
            this.setState({
                deleteLocationSuccess: this.props.deleteLocationSuccess,
                deletePopup: false
            });
            this.props.dispatch(getLocationListOfQuotations(this.props.match.params.quotId));
            toast(this.props.deleteLocationSuccess?.data);
        } if (prevProps.cloneLocationSuccess !== this.props.cloneLocationSuccess) {
            this.setState({
                cloneLocationSuccess: this.props.cloneLocationSuccess,
                clonePopup: false
            });
            this.props.dispatch(getLocationListOfQuotations(this.props.match.params.quotId));
            toast(this.props.cloneLocationSuccess?.message);
        } if (prevProps.quotationData !== this.props.quotationData) {
            this.setState({
                ignoredFields: this.props.quotationData?.ignoredFields
            });
        }
    }
    componentDidMount() {
        if (this.props.match.params.quotId !== "null") {
            this.props.dispatch(getLocationListOfQuotations(this.props.match.params.quotId));
            this.props.dispatch(getQutation(this.props.match.params.quotId));
        }
    }

    render() {
        const { handleSubmit } = this.props;
        const { ignoredFields, show, location, deletePopup, deletedId, clonePopup, locationId } = this.state;
        const { history } = this.props;

        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <img src={Orange} alt="..." className="head-img" />
                            <h5 className='po_link' onClick={() => history.push("/" + role + "/quotations")}>Quotations</h5>
                            <i className="fas fa-arrow-right" style={{ margin: "10px 0 0 -10px" }}></i>
                            <h6 >{changeTitleStr(this.props.match.params.add)}</h6>
                            <hr className="line-new"></hr>
                            <div className="order-page-title">
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/customerDetails/${this.props.match.params.id}/${this.props.match.params.quotId}`}>
                                    Customer Details
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/contentBody/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Content of the Letter
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/location/${this.props.match.params.id}/${this.props.match.params.quotId}`} className="active">
                                    Location
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/quotationBody/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Quotation Body
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/footer/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Footer
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/termsAndConditions/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Terms & Conditions
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/prepared/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Prepared by
                                </Link>
                            </div>
                            <div className="purchase-order">
                                <section className="po-header">
                                    <Row className='ignore_field_body' >
                                        <Col md={2}>
                                            <h4>Project Groups</h4>
                                        </Col>
                                        <Col md={5}>
                                            <h4 className="txt-right">Hide Columns:</h4>
                                        </Col>
                                        <Col md={5}>
                                            <div className="qutation_check">
                                                <div className="customer-check">
                                                    <Form.Check checked={ignoredFields &&
                                                        ignoredFields?.includes("LOCATION_AREA")
                                                        ? true : false} inline label="Area" onChange={this.hideColumnHandler} name="group1" type="checkbox" defaultValue="LOCATION_AREA" className="form-check-label" />
                                                </div>
                                                <div className="customer-check">
                                                    <Form.Check checked={ignoredFields &&
                                                        ignoredFields?.includes("LOCATION_DIMENSION")
                                                        ? true : false} inline label="Area Dimensions" onChange={this.hideColumnHandler} name="group1" type="checkbox" defaultValue="LOCATION_DIMENSION" className="form-check-label" />
                                                </div>
                                                <div className="customer-check">
                                                    <Form.Check checked={ignoredFields &&
                                                        ignoredFields?.includes("DRAWING_REFERENCE")
                                                        ? true : false} inline label="Dwg Ref No." onChange={this.hideColumnHandler} name="group1" type="checkbox" defaultValue="DRAWING_REFERENCE" className="form-check-label" />
                                                </div>
                                                <div className="customer-check">
                                                    <Form.Check checked={ignoredFields &&
                                                        ignoredFields?.includes("LOCATION_SET_COUNT")
                                                        ? true : false} inline label="Set Count" onChange={this.hideColumnHandler} name="group1" type="checkbox" defaultValue="LOCATION_SET_COUNT" className="form-check-label" />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </section>
                                <div className="qutation-page" >
                                    <form onSubmit={handleSubmit(this.addLocationHandler)} >
                                        <FieldArray name="locations" component={this.renderLocation} />
                                    </form>
                                </div>
                                <EditLocation show={show} onHide={this.handleClose} initialValues={location} onSubmit={this.editHandler} />
                                <Modal
                                    show={deletePopup}
                                    onHide={() => {
                                        this.setState({
                                            deletePopup: false
                                        });
                                    }}
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>
                                    <div className="modal-success">
                                        <img src={del} alt=".." />
                                        <h5>Are you sure you want to delete this Location?</h5>
                                    </div>
                                    <div className="txt-center">
                                        <button onClick={() => {
                                            this.setState({
                                                deletePopup: false
                                            });
                                        }}
                                            className="no_btn"
                                        >
                                            Cancel
                                        </button>
                                        <button onClick={() => {
                                            this.setState({
                                                deletePopup: false
                                            });
                                            this.props.dispatch(deleteLocationToQuotation(deletedId));
                                        }
                                        } className="okay"> Okay
                                        </button>
                                    </div>
                                </Modal>
                                <Modal
                                    show={clonePopup}
                                    onHide={() => {
                                        this.setState({
                                            clonePopup: false
                                        });
                                    }}
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>
                                    <div className="modal-success">
                                        <img src={clone} alt=".." />
                                        <h5>Are you sure you want to clone this Location?</h5>
                                    </div>
                                    <div className="txt-center">
                                        <button onClick={() => {
                                            this.setState({
                                                clonePopup: false
                                            });
                                        }}
                                            className="no_btn"
                                        >
                                            Cancel
                                        </button>
                                        <button onClick={() => {
                                            this.setState({
                                                deletePopup: false
                                            });
                                            this.props.dispatch(cloneLocation(locationId));
                                        }
                                        } className="okay"> Okay
                                        </button>
                                    </div>
                                </Modal>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    let initValues;
    let quote = state.boq.boqIdData;
    let customer = state.customer.customer;
    let newCustomer = quote.billOfQuantityId !== null ? {
        'customerInitials': quote ? quote.customerInitials : '',
        'firstName': quote ? quote.customerFirstName : '',
        'lastName': quote ? quote.customerLastName : '',
        'phone': quote ? quote.customerNumber : '',
        'email': quote ? quote.customerEmail : '',
        'address': quote ? quote.customerAddress : '',
        'city': quote ? quote.customerCity : '',
        'state': quote ? quote.customerState : '',
        'pincode': quote ? quote.customerPincode : '',
    } : {};

    initValues = Object.keys(customer).length !== 0 ? customer : newCustomer;

    return {
        initialValues: {
            locations: [{ area: '', dimension: '', drawingReferenceNumber: '', setCount: '1' }]
        },
        addLocationSuccess: state.qutation.addLocationSuccess,
        hideColumns: state.qutation.hideColumns,
        quoteLocations: state.qutation.quoteLocations,
        quotationData: state.qutation.quotationData,
        deleteLocationSuccess: state.qutation.deleteLocationSuccess,
        editLocationSuccess: state.qutation.editLocationSuccess,
        cloneLocationSuccess: state.qutation.cloneLocationSuccess
    }
}
const VendorDetailsForm = reduxForm({
    form: 'quotationLocationFormRedux',
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate
})(Location)

export default connect(mapStateToProps)(VendorDetailsForm);
