import React, { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import Edit from "../../../assets/images/ui.svg";
import Delete from "../../../assets/images/ux.svg";
import Spinner from "../../../Components/loader";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import ProductForm from './productForm';
import { role } from '../../../actions';


const ViewProducts = (props) => {

    const { open, id, productsList, loading, edit, reset, productItem} = props;
    
    return (
        <Fragment>
            {open ?
                <div className="table-back-pack table-blue-bg package-products-view">
                    <Table responsive>
                        <thead>
                            <th>Article ID</th>
                            <th>Product Name</th>
                            <th >QTY</th>
                            {role === "admin" && <th>Actions</th>}
                        </thead>
                        <tbody>
                        {productsList && productsList?.length > 0 ? productsList?.map((item, index) => {
                            return (
                                <tr key={index} >
                                    <td>{item?.articleId}</td>
                                    <td>{item?.productName}</td>
                                    <td>{item?.quantity}</td>
                                    {role === "admin" && <td style={{ width: "90px", textAlign: "center" }}>
                                        <img src={Edit} alt="" className="Edit-staffs" onClick={() => {edit(item)}}/>
                                    </td>}
                                </tr>
                      )
                    }) :
                      !loading ?
                        (<tr>
                             <td colSpan="5" className="pack-pos-center">
                                No Records Found
                             </td>
                         </tr>) :
                        <Spinner />
                    }
                        </tbody>
                    </Table>
                    <ProductForm reset={reset} productItem={productItem}/> 
                </div> : null}
        </Fragment>
    );
}


const  mapStateToProps = (state) => {
    const {packages} = state;
    return {
        productsList: packages.productsList?.data?.packageProductResDtoList,
        loading: packages.loading
    }
}

export default connect(mapStateToProps)(ViewProducts);