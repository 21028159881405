import React, { useState , useEffect} from 'react'
import { Field, reduxForm } from 'redux-form';
import { changeDateFormat, clearAddOrEditPackageResponseAction, clearSingleProduct, clearSuccessMessage, createProduct, dateFormat, role, updateProduct } from '../../actions';
import { Row, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import profile from "../../assets/images/we.svg";
import { S3_root } from '../../actions';
import validate from './Validate';
import AddNewPrice from "./AddNewPrice";
import ViewHistory from "./ViewHistory";
import plus from "../../assets/images/pl.svg";
import { Switch } from 'antd';
import PackageForm from '../packages/module/packageForm';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { checkDataIsEmpty } from '../../helpers/checkDataIsEmpty';



const renderField = ({ input, placeholder, maxLength, type, readOnly, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength} readOnly={readOnly} />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

const renderSelectField = ({ input, meta: { touched, error }, children }) => (
    <div>
        <div>
            <select {...input} className='form-control form-select mt-4'>
                {children}
            </select>
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

const file_upload = ({ input, type, imgHandler, label, meta: { touched, error, warning } }) => {
    delete input.value
    return (
        <div className=''>
            <label htmlFor={input.name}>
                <input {...input} type={type} onChange={imgHandler} accept="image/*" />
                {touched && error && <p className="require">{error}</p>}
            </label>
        </div>
    )
}


const ProductForm = props => {
    const { error, handleSubmit, imgHandler, empty, show, onHide, selectedFile, filePick, page, img, units, initialValues, history, getPriceData, productId, product_history, getHistory, reset, handleShow, str,onClearAddOrEditProductDispatch, singleProduct, onClearSingleProductDispatch, onCreateProductDispatch, onUpdateProductDispatch, formData, success_msg, onClearSuccessMsgDispatch, resetPackage} = props;

    const date = changeDateFormat(initialValues?.effectiveOnDate);

    let imgUrl = img && img !== null ? S3_root + img : profile;
    const status = [
        {
            status: "Active",
            value: "ACTIVE",
        },
        {
            status: "Inactive",
            value: "INACTIVE",
        }
    ];


    let date1 = new Date();
    let location = useLocation();
    let hstry = useHistory();
    let oldDate = dateFormat(initialValues?.effectiveOnDate)
    let currentDate = dateFormat(date1);
    let checkDate = currentDate < oldDate;
    const queryParams = new URLSearchParams(location.search);
    const isPacked = queryParams.get('isPacked');
    const isSaved = queryParams.get('isSaved');
    const [isPackage, setIsPackage] = useState(false);
    const [packageShow, setPackageShow] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isClicked, setIsSwitchClicked] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isProductClick, setIsProductClick] = useState(false);
    const [packageForm, setPackageForm] = useState({ articleId: '', packageName: "", unitPrice: null });

    const renderSwitch = ({ input }) => (
        <Switch onChange={input.onChange} 
        disabled={empty === "edit" && singleProduct?.packaged===true}
        checked={empty === "edit" ? singleProduct?.packaged : isPackageSelected()}
        />
    );

    const isPackageSelected = () => {
        return isPacked === "true" && isSaved === "true"
    }

    const switchToPackage = () => {
        setPackageShow(false);
        setIsConfirmed(true);
        setIsPackage(true);
        if(empty === "edit") {   
            onClearSingleProductDispatch();
        }
        hstry.push("?create=1")
    }


    const showModal = () => {
        if (str === "add") {
            reset();
            handleShow();
        }
    }

    const handleChange = (e, field) => {
        const { value } = e.target;
        setPackageForm({
            ...packageForm,
            [field]: value,
        });
    };

    const togglePackage = () => {
        setIsSwitchClicked(true);
        setIsPackage(!isPackage);
    }

    const completeResetOnClose = () => {
        setIsPackage(false);
        setIsConfirmed(false);
        setIsSwitchClicked(false);
        setIsProductClick(false);
        onClearSuccessMsgDispatch();
    }

    const onCloseModel = () => {
        completeResetOnClose();
        reset();
        onHide();
    }

    const resetForm = () => {
        setShowForm(false);
        setPackageForm({});
        completeResetOnClose();
        onClearAddOrEditProductDispatch();
        reset();
        onHide();
    }

    useEffect(() => {
        if(empty === "edit" && singleProduct) {
            setIsPackage(singleProduct?.packaged || false);
            setPackageForm({ articleId: singleProduct?.articleId, packageName: singleProduct?.productName, unitPrice: singleProduct?.currentPrice });
        }
    }, [singleProduct])

    useEffect(() => {
        if(resetPackage === true) {
            setIsPackage(false);
            setIsProductClick(false);
        }
    },[resetPackage])

    useEffect(() => {
        if(checkDataIsEmpty(formData) === false) {
            if(isProductClick === true) {
                if(empty === "edit") {
                    formData.append("isPackaged",  isPackage);
                    onUpdateProductDispatch(formData);
                } else {
                    formData.append("isPackaged",  false);
                    onCreateProductDispatch(formData);
                }
            } else if(isPackage === true && isConfirmed === true) {
                    formData.append("isPackaged",  true);
                    if(empty === "edit") {
                        onUpdateProductDispatch(formData);
                    } else {
                        onCreateProductDispatch(formData);
                    }
            } else if(isPackage === true && isConfirmed === false && isClicked === true) {
                  setPackageShow(true);
            }
        } 
    }, [formData, isPackage, isConfirmed, isClicked, isProductClick])

    useEffect(() => {
       if(isPackage === true && isClicked === true && isProductClick === false) {
        document.getElementById("invisibleButton").click();
       }
    }, [isPackage, isClicked, isProductClick])

    useEffect(() => {
        if(success_msg === 200 && isPackage === true && isClicked === true && isProductClick === false) { 
            setShowForm(true);
        }  else 
        if(success_msg === 200 && isProductClick === true) {
            onCloseModel();
        }
    }, [success_msg, isPackage, isProductClick, isClicked])

    return (
        <>
            <button className="product-add-staffs" onClick={showModal}>
                <img src={plus} alt="" /> Add Product
            </button>
            <Modal
                show={show}
                size="lg"
                onHide={() => onCloseModel()}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-staff main">
                    <form onSubmit={handleSubmit}>
                         <div className="head">
                            <div>
                                <i class="fas fa-arrow-left" onClick={() => onCloseModel()}></i>
                                 <h4>{empty === "edit" ? "PRODUCT DETAILS" : "Add New Product"}</h4>
                            </div>
                            {empty === "edit" ?
                                <div>
                                    <Field name="status" component="select" className="form-control select-control" >
                                        <option value="" disabled={true}>Select One</option>
                                        {status.map((item, i) =>
                                            <option value={item.status} key={i}>{item.value}</option>)}
                                    </Field>
                                    <div className="triangle-status"></div>
                                </div>
                                : null}
                        </div>
                        <Row className="modal-staff-body">
                            <Col md={6} xs={6}>
                                <Row className="modal-form-row">
                                    {empty === "edit" ? <Col md={12}>
                                        <label class="form-control-placeholder" for="name">Product ID </label>
                                        <Field name="productId" type="text" component={renderField} maxLength="6" readOnly />
                                    </Col> : null}
                                    <Col md={12}>
                                        <label class="form-control-placeholder" for="name">Article ID <span>*</span></label>
                                        <Field name="articleId" type="text" component={renderField} onChange={(e) => handleChange(e, 'articleId')}/>
                                    </Col>
                                    <Col md={12}>
                                        <label class="form-control-placeholder" for="name">{`Product Name`} <span>*</span></label>
                                        <Field name="productName" type="text" component={renderField} onChange={(e) => handleChange(e, 'packageName')} />
                                    </Col>
                                    <Col md={12} className="mt-1">
                                        <div className='initial'>
                                            <label class="form-control-placeholder" for="name">Select Unit <span>*</span></label>
                                            <Field name="unitId" component={renderSelectField} className="form-control form-select" defaultValue={initialValues?.unitName}>
                                                <option value="" disabled={true} hidden={true}></option>
                                                {units?.map(unit =>
                                                    <option value={unit.unitId} key={unit.unitId}>{unit.unitName}</option>)}
                                            </Field>
                                            <div className="triangle-bottom"></div>
                                        </div>
                                    </Col>
                                    {page === "create" ? <Col md={12}>
                                        <label class="form-control-placeholder" for="name">Enter Price <span>*</span></label>
                                        <Field name="newPrice" type="text" component={renderField} onChange={(e) => handleChange(e, 'unitPrice')}/>
                                    </Col> : null}
                                    <Col md={12}>
                                        <label class="form-control-placeholder" for="name">Threshold Value <span>*</span></label>
                                        <Field name="thresholdValue" type="text" component={renderField} />
                                    </Col>
                                    {empty === "edit" ? <Col md={12}>
                                        <label class="form-control-placeholder" for="name">New Price</label>
                                        <Field name="currentPrice" type="text" component={renderField} readOnly />
                                    </Col> : null}
                                    {empty === "edit" ? <Col md={12} className="mt-4">
                                        <AddNewPrice getData={getPriceData} productId={productId} />
                                    </Col> : null}
                                    {empty === "edit" && checkDate ? <Col md={12} >
                                        <label class="form-control-placeholder" for="name">Current Price</label>
                                        <Field name="effectivePrice" type="text" component={renderField} placeholder="Enter Current Price" readOnly />
                                    </Col> : null}
                                    {empty === "edit" ? <Col md={12} >
                                        <label class="form-control-placeholder" for="name">Price Effective Date/Time</label>
                                        <Field name="date" type="text" component={renderField} placeholder={date} readOnly />
                                    </Col> : null}
                                    {empty === "edit" ? <Col md={12} style={{ marginTop: "20px" }}>
                                        <ViewHistory history={product_history} get={getHistory} />
                                    </Col> : null}
                                    <Col md={12}>
                                        <button type='submit' className="modal-btn sub-modal-btn" onClick={() => {
                                            setIsProductClick(true);
                                        }}>
                                            {empty === "edit" ? "Update Product" : "Create Product"}
                                        </button>
                                    </Col>

                                </Row>
                            </Col>
                            <Col md={6} xs={6} className="profile">
                                <h4> Product Image</h4>
                                <div className='product-svg'>
                                    <img
                                        src={filePick ? URL.createObjectURL(selectedFile) : imgUrl}
                                        alt=".."
                                        className="profile-img"
                                    />
                                </div>
                                <label className="upload">  {page === "edit" ? "Change photo" : "Upload photo"}
                                    <Field name="imageUrl" type="file" label="Upload Photo" component={file_upload} imgHandler={imgHandler} />
                                </label>
                                <div className='switch-package'>
                                    <p className={isPackageSelected() ? "unselect-ptag" : "select-ptag"}>Product</p>
                                    <Field name="isPackage" component={renderSwitch} onChange={togglePackage}/>
                                    <p className={isPackageSelected() === false ? "unselect-ptag" : "select-ptag"}>Package</p>
                                </div>
                                <button id="invisibleButton" type='submit' style={{display: "none"}}/>
                            </Col>
                        </Row>
                    </form>
                </div>
            </Modal>
            <Modal
            show={packageShow}
            onHide={() => {
              setPackageShow(false);
            }}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <div className="modal-success">
              <h5>Are You Sure! You want to create package ?</h5>
            </div>
            <div className="txt-center">
              <button className="no" onClick={() => {
                    setPackageShow(false)
                }}>cancel</button>
              <button className="okay" onClick={() => {
                    switchToPackage();
                }}>okay</button>
            </div> 
          </Modal>
          <div style={{display: "none"}}>
            <PackageForm showForm={showForm} packageDetails={packageForm} reset={resetForm}/>
          </div>
        </>
    )
}

function mapStateToProps(state) {
    let page = state.product.page;

    let initValues = page === "edit" ? state.product.singleProduct : {};

    return {
        initialValues: initValues,
        singleProduct: state.product.singleProduct,
        page: state.product.page,
        img: state.product.img,
        product_history: state.product.product_history,
        success_msg: state.product.success_msg?.status
    }
}

const mapDispatchToProps = (dispatch) => ({
    onClearAddOrEditProductDispatch: () => dispatch(clearAddOrEditPackageResponseAction()),
    onClearSingleProductDispatch: () => dispatch(clearSingleProduct()),
    onCreateProductDispatch: (formData) => dispatch(createProduct(formData)),
    onUpdateProductDispatch: (formData) => dispatch(updateProduct(formData)),
    onClearSuccessMsgDispatch: () => dispatch(clearSuccessMessage())
})

const Form = reduxForm({
    form: "ProductForm",
    destroyOnUnmount: false,
    enableReinitialize: true,
    // validate
})(ProductForm);

export default connect(mapStateToProps, mapDispatchToProps)(Form);
